// CareerDevelopmentPlan.js

import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Star as StarIcon,
  Build as BuildIcon,
  Timer as TimerIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
  subSectionTitle: {
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
  },
  listItem: {
    paddingLeft: 0,
  },
}));

const CareerDevelopmentPlan = ({ content }) => {
  const classes = useStyles();

  if (!content || typeof content !== "object")
    return <Typography>Invalid career development plan data</Typography>;

  return (
    <Box>

      <Grid container spacing={3}>
        {/* Short-Term Goals */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <StarIcon color="primary" />
                <Typography
                  variant="subtitle1"
                  className={classes.subSectionTitle}
                >
                  Short-Term Goals
                </Typography>
              </Box>
              {content.shortTermGoals && content.shortTermGoals.length > 0 ? (
                <List>
                  {content.shortTermGoals.map((goal, index) => (
                    <ListItem key={index} className={classes.listItem}>
                      <ListItemText primary={`• ${goal}`} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>No short-term goals provided.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Long-Term Goals */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <StarIcon color="secondary" />
                <Typography
                  variant="subtitle1"
                  className={classes.subSectionTitle}
                >
                  Long-Term Goals
                </Typography>
              </Box>
              {content.longTermGoals && content.longTermGoals.length > 0 ? (
                <List>
                  {content.longTermGoals.map((goal, index) => (
                    <ListItem key={index} className={classes.listItem}>
                      <ListItemText primary={`• ${goal}`} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>No long-term goals provided.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Actions */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <BuildIcon color="action" />
                <Typography
                  variant="subtitle1"
                  className={classes.subSectionTitle}
                >
                  Actions
                </Typography>
              </Box>
              {content.actions && content.actions.length > 0 ? (
                <List>
                  {content.actions.map((action, index) => (
                    <ListItem key={index} className={classes.listItem}>
                      <ListItemText primary={`• ${action}`} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>No actions provided.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Timelines */}
        <Grid item xs={12} md={6}>
          <Card elevation={3} className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <TimerIcon color="secondary" />
                <Typography
                  variant="subtitle1"
                  className={classes.subSectionTitle}
                >
                  Timelines
                </Typography>
              </Box>
              {content.timelines && content.timelines.length > 0 ? (
                <List>
                  {content.timelines.map((timeline, index) => (
                    <ListItem key={index} className={classes.listItem}>
                      <ListItemText primary={`• ${timeline}`} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>No timelines provided.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CareerDevelopmentPlan;
