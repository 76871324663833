// src/components/ContactUsForm.jsx
import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import contactService from "../util/contact"; // Ensure this path is correct
import Slide from "@material-ui/core/Slide";

// Custom Slide Transition for Snackbar
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

// Define custom styles leveraging the theme
const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  submitButton: {
    position: "relative",
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    color: theme.palette.primary.contrastText,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
}));

function ContactUsForm({ showNameField = true }) {
  const classes = useStyles();
  const theme = useTheme();

  // react-hook-form setup with updated API
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const onSubmit = async (data) => {
    setPending(true);
    setFormAlert(null);

    try {
      await contactService.submit(data);
      reset();
      setFormAlert({ type: "success", message: "Your message has been sent!" });
    } catch (error) {
      setFormAlert({
        type: "error",
        message: error.message || "Something went wrong. Please try again.",
      });
    } finally {
      setPending(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {/* Snackbar for alerts */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {formAlert && (
          <Alert
            onClose={handleSnackbarClose}
            severity={formAlert.type}
            variant="filled"
          >
            {formAlert.message}
          </Alert>
        )}
      </Snackbar>

      <Box className={classes.formContainer}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            {showNameField && (
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="Your Name"
                  name="name"
                  fullWidth
                  required
                  error={Boolean(errors.name)}
                  helperText={errors.name ? errors.name.message : ""}
                  inputRef={register({
                    required: "Please enter your name",
                    maxLength: {
                      value: 50,
                      message: "Name cannot exceed 50 characters",
                    },
                  })}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={showNameField ? 6 : 12}>
              <TextField
                variant="outlined"
                label="Your Email"
                name="email"
                type="email"
                fullWidth
                required
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : ""}
                inputRef={register({
                  required: "Please enter your email",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Please enter a valid email address",
                  },
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Message"
                name="message"
                multiline
                rows={6}
                fullWidth
                required
                error={Boolean(errors.message)}
                helperText={errors.message ? errors.message.message : ""}
                inputRef={register({
                  required: "Please enter a message",
                  minLength: {
                    value: 10,
                    message: "Message should be at least 10 characters",
                  },
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <Box position="relative" className={classes.submitButton}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={pending}
                >
                  {pending ? "Sending..." : "Send Message"}
                </Button>
                {pending && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

export default ContactUsForm;
