import React, { useEffect, useState, useContext } from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Divider,
} from "@material-ui/core";
import {
  AccountBalanceWallet as WalletIcon,
  TrendingDown as TrendingDownIcon,
} from "@material-ui/icons";
import { useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { db } from "../util/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { FormContext } from "./FormContext";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down("xs")]: { marginBottom: theme.spacing(2) },
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: { padding: theme.spacing(1.5) },
  },
  planTitle: {
    fontWeight: 700,
    fontSize: "1.4rem",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: { fontSize: "1.2rem" },
  },
  content: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: { padding: theme.spacing(1.5) },
  },
  creditsInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: { marginBottom: 0 },
  },
  creditsIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(0.5),
      fontSize: "1.3rem",
    },
  },
  creditsText: {
    fontWeight: 600,
    fontSize: "1.1rem",
    [theme.breakpoints.down("xs")]: { fontSize: "1rem" },
  },
  estimatedCredits: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("xs")]: { justifyContent: "center" },
  },
  divider: { margin: theme.spacing(1, 0) },
  buttonWrapper: {
    display: "inline-block",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
    "&:active": {
      transform: "scale(0.95)",
    },
  },
  button: {
    minWidth: 150,
    height: 56,
    borderRadius: 28,
    textTransform: "none",
    fontSize: "1.1rem",
    fontWeight: 600,
    boxShadow:
      "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow:
        "0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)",
    },
  },
  buttonText: {
    marginLeft: theme.spacing(1),
  },
}));

const GenerationCreditsDisplay = () => {
  const classes = useStyles();
  const router = useRouter();
  const auth = useAuth();
  const { formData } = useContext(FormContext);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (!auth.user) {
      setUserData(null);
      return;
    }
    const userDocRef = doc(db, "users", auth.user.uid);
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      setUserData(docSnapshot.exists() ? docSnapshot.data() : null);
    });
    return unsubscribe;
  }, [auth.user]);

  if (userData === null) {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography variant="body2" align="center">
            Loading plan and credits...
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const { credits: userCredits = 0, planId = "Free" } = userData;
  const totalToolkitSelections = (formData.targetJobInformation || []).reduce(
    (total, job) =>
      total + Object.values(job.generationOptions || {}).filter(Boolean).length,
    0
  );
  const estimatedCreditsRemaining = Math.max(
    userCredits - totalToolkitSelections,
    0
  );

  return (
    <Card className={classes.card}>
      <div className={classes.header}>
        <Typography className={classes.planTitle}>
          {planId.charAt(0).toUpperCase() + planId.slice(1)} Plan
        </Typography>
      </div>
      <CardContent className={classes.content}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6}>
            <div className={classes.creditsInfo}>
              <WalletIcon className={classes.creditsIcon} />
              <Typography className={classes.creditsText}>
                Credits: {userCredits}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.creditsInfo}>
              <TrendingDownIcon className={classes.creditsIcon} />
              <Typography className={classes.creditsText}>
                Estimated After: {estimatedCreditsRemaining}
              </Typography>
            </div>
          </Grid>
        </Grid>
        {userCredits === 0 && (
          <>
            <Divider className={classes.divider} />
            <Grid container justifyContent="center">
              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => router.push("/pricing")}
                  className={classes.button}
                  aria-label="Upgrade Plan"
                >
                  <Typography variant="button" className={classes.buttonText}>
                    Upgrade Plan
                  </Typography>
                </Button>
              </div>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default GenerationCreditsDisplay;
