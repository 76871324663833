import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(4),
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.1)",
    borderRadius: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark" ? "#2e2e2e" : theme.palette.background.paper,
    color:
      theme.palette.type === "dark" ? theme.palette.grey[300] : theme.palette.text.primary,
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: theme.shadows[20],
    },
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    border: `3px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[4],
  },
  preformattedText: {
    whiteSpace: "pre-wrap",
    fontSize: "1.1rem",
    lineHeight: 1.6,
    color:
      theme.palette.type === "dark" ? theme.palette.grey[300] : theme.palette.text.primary,
    fontFamily: "'Roboto', sans-serif",
  },
  name: {
    fontSize: "1.8rem",
    fontWeight: "700",
    color:
      theme.palette.type === "dark" ? theme.palette.common.white : theme.palette.text.primary,
    marginTop: theme.spacing(2),
    transition: "color 0.3s ease",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  role: {
    fontSize: "1.2rem",
    color:
      theme.palette.type === "dark" ? theme.palette.grey[400] : theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  bioContainer: {
    marginTop: theme.spacing(3),
  },
}));

function BioSection(props) {
  const classes = useStyles();
  const theme = useTheme();

  const items = [
    {
      avatar: "https://live.staticflickr.com/65535/52596685521_6054de480d_q.jpg",
      name: "Kylan Thomson",
      role: "Software Engineer & Creator of Resume Revival",
      bio: `
Hello! I'm Kylan, the creator of Resume Revival—a platform crafted to empower job seekers by using technology to bring their best selves forward.

With a background in full-stack development, I built Resume Revival to bridge the gap between skill and opportunity. By combining AI and a refined, professional touch, my goal is to provide application materials that resonate with recruiters and elevate candidates in competitive markets.

This project is a reflection of my dedication to innovation and personalized user experience. Beyond Resume Revival, I'm constantly exploring tech trends and expanding my skills to deliver solutions that make a meaningful impact. I look forward to supporting your career journey with tools designed to set you apart.
      `,
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={8} md={6} key={index}>
              <Zoom in={true} timeout={1000}>
                <Card className={classes.card}>
                  <CardContent>
                    <Box className={classes.avatarWrapper}>
                      <Avatar
                        src={item.avatar}
                        alt={item.name}
                        className={classes.avatar}
                      />
                    </Box>
                    <Box textAlign="center">
                      <Typography variant="h5" className={classes.name}>
                        {item.name}
                      </Typography>
                      <Typography variant="subtitle1" className={classes.role}>
                        {item.role}
                      </Typography>
                      <Box mt={3} textAlign="left" className={classes.bioContainer}>
                        <Fade in={true} timeout={1500}>
                          <Typography
                            variant="body1"
                            component="div"
                            className={classes.preformattedText}
                          >
                            {item.bio.split("\n").map((line, idx) => (
                              <span key={idx}>
                                {line}
                                <br />
                              </span>
                            ))}
                          </Typography>
                        </Fade>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Zoom>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default BioSection;
