// PersonalBrandingTips.js

import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: 0,
  },
}));

const PersonalBrandingTips = ({ content }) => {
  const classes = useStyles();

  if (!content || typeof content !== "object")
    return <Typography>Invalid personal branding tips data</Typography>;

  return (
    <Box>
      <List>
        {content.tips && content.tips.length > 0 ? (
          content.tips.map((tip, index) => (
            <ListItem key={index} className={classes.listItem}>
              <ListItemText primary={`• ${tip}`} />
            </ListItem>
          ))
        ) : (
          <Typography>No tips available.</Typography>
        )}
      </List>
    </Box>
  );
};

export default PersonalBrandingTips;
