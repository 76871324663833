// src/hooks/useSticky.js

import { useState, useEffect } from "react";

/**
 * Custom hook to handle sticky behavior based on scroll position.
 * @param {number} threshold - The scroll threshold to trigger the sticky state.
 * @returns {boolean} - Whether the element should be sticky.
 */
const useSticky = (threshold) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop > threshold);
    };

    // Initial check in case the user is already scrolled
    handleScroll();

    window.addEventListener("scroll", handleScroll, { passive: true });

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [threshold]);

  return isSticky;
};

export default useSticky;
