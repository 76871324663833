// validationSchema.js
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  streetAddress: Yup.string().required("Street Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State/Province is required"),
  zipCode: Yup.string().required("Zip/Postal Code is required"),
  country: Yup.string().required("Country is required"),
  phone: Yup.string()
    .required("Phone is required")
    .matches(/^[0-9()+-\s]*$/, "Phone number is not valid"),
  email: Yup.string()
    .required("Email Address is required")
    .email("Email Address is not valid"),
  linkedinUrl: Yup.string().url("LinkedIn URL is not valid").notRequired(),
  websiteUrl: Yup.string()
    .url("Website/Portfolio URL is not valid")
    .notRequired(),
  twitterHandle: Yup.string().notRequired(),
  githubUsername: Yup.string().notRequired(),
  otherSocialProfile: Yup.string().notRequired(),
  professionalTitle: Yup.string().notRequired(),
  summary: Yup.string()
    .max(800, "Summary cannot exceed 800 characters")
    .notRequired(),
});
