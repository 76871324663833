// src/components/PricingSection.jsx

import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import StarIcon from "@material-ui/icons/Star";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Carousel from "react-material-ui-carousel";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Info as InfoIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    borderRadius: "12px",
    boxShadow: theme.shadows[2],
    overflow: "visible",
    transition: "transform 0.3s, box-shadow 0.3s",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: theme.shadows[4],
    },
  },
  popularCard: {
    border: `2px solid ${theme.palette.primary.main}`,
    transform: "scale(1.02)",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      transform: "none",
      borderWidth: "1px",
    },
  },
  bestValueCard: {
    border: `2px solid ${theme.palette.secondary.main}`,
    transform: "scale(1.02)",
    zIndex: 2,
    [theme.breakpoints.down("sm")]: {
      transform: "none",
      borderWidth: "1px",
    },
  },
  cardContent: {
    padding: theme.spacing(4),
    textAlign: "center",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  price: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tag: {
    position: "absolute",
    top: theme.spacing(-2),
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 2),
    fontSize: "0.75rem",
    fontWeight: "bold",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    boxShadow: theme.shadows[3],
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.65rem",
      padding: theme.spacing(0.25, 1.5),
    },
  },
  bestValueTag: {
    backgroundColor: theme.palette.secondary.main,
  },
  tagIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.85rem",
    },
  },
  disclaimer: {
    marginTop: theme.spacing(2),
    fontSize: "0.85rem",
    color: theme.palette.text.secondary,
    textAlign: "center",
    padding: theme.spacing(0, 2),
  },
  featureCard: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    borderRadius: "12px",
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  featureTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  featureGrid: {
    marginTop: theme.spacing(2),
  },
  featureItem: {
    display: "flex",
    alignItems: "flex-start",
  },
  featureIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(0),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5, 4),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: "8px",
    transition: "background-color 0.3s, transform 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      transform: "scale(1.02)",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 3),
      fontSize: "0.9rem",
    },
  },
  testimonialContainer: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: "12px",
    boxShadow: theme.shadows[2],
    position: "relative",
    maxWidth: "800px", // Limited width
    marginLeft: "auto", // Center horizontally
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  testimonialText: {
    fontStyle: "italic",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.95rem",
    },
  },
  testimonialAuthor: {
    fontWeight: "bold",
    textAlign: "right",
    color: theme.palette.text.primary,
    fontSize: "0.9rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.85rem",
    },
  },
  carouselNavButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
    zIndex: 2,
    minWidth: "auto",
    padding: theme.spacing(1),
    borderRadius: "50%",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
    },
  },
  prevButton: {
    left: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      left: theme.spacing(1),
    },
  },
  nextButton: {
    right: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      right: theme.spacing(1),
    },
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      marginRight: theme.spacing(1.5),
    },
  },
  freeTrialInfo: {
    marginTop: theme.spacing(4), // Adjusted for better spacing
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: "12px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "420px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  freeTrialIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
  subtitle1Enhanced: {
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "0.05em",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
    color:
      theme.palette.type === "dark" ? "#FFFFFF" : theme.palette.primary.dark,
    textAlign: "center",
    fontSize: "0.9rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  linkButton: {
    textDecoration: "none",
  },
}));

function PricingSection(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAuth();

  const features = [
    "AI-generated resumes and cover letters tailored to your experience and job descriptions.",
    "Downloadable, Windows and Mac-friendly .docx files for easy editing and submission.",
    "Unlimited revisions to fine-tune your materials until they're just right.",
    "Access to an ever-growing library of templates to match your style.",
    "Save time and effort by letting our AI do the heavy lifting, so you can focus on landing your dream job!",
  ];

  const plans = [
    {
      id: "basic",
      name: "Basic",
      price: "9.95",
      credits: 100,
      description: "Ideal for individuals starting their job search.",
    },
    {
      id: "professional",
      name: "Pro",
      price: "19.95",
      credits: 300,
      isMostPopular: true,
      description:
        "Perfect for professionals looking to enhance their applications.",
    },
    {
      id: "premium",
      name: "Premium",
      price: "29.95",
      credits: 1000,
      isBestValue: true,
      description: "Best for those who want maximum flexibility and resources.",
    },
  ];

  const testimonials = [
    {
      text: "This service completely transformed my job applications. I landed interviews within weeks!",
      author: "Jane Doe, Software Engineer",
      avatar: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    {
      text: "The AI-generated resumes are spot on. Highly recommend the Pro plan for serious professionals.",
      author: "John Smith, Marketing Specialist",
      avatar: "https://randomuser.me/api/portraits/men/46.jpg",
    },
    {
      text: "With the Premium plan, I received personalized support that helped me land my dream job!",
      author: "Emily Johnson, Product Manager",
      avatar: "https://randomuser.me/api/portraits/women/68.jpg",
    },
  ];

  const CustomPrevButton = ({ onClick }) => (
    <Button
      onClick={onClick}
      className={`${classes.carouselNavButton} ${classes.prevButton}`}
      aria-label="Previous Testimonial"
      size="small"
    >
      <ArrowBackIos />
    </Button>
  );

  const CustomNextButton = ({ onClick }) => (
    <Button
      onClick={onClick}
      className={`${classes.carouselNavButton} ${classes.nextButton}`}
      aria-label="Next Testimonial"
      size="small"
    >
      <ArrowForwardIos />
    </Button>
  );

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {/* Pricing Options */}
        <Grid container justifyContent="center" spacing={4}>
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.id}>
              <Card
                className={`${classes.card} ${
                  plan.isMostPopular
                    ? classes.popularCard
                    : plan.isBestValue
                    ? classes.bestValueCard
                    : ""
                }`}
                elevation={plan.isMostPopular || plan.isBestValue ? 6 : 2}
                aria-label={`${plan.name} Plan`}
              >
                {/* Tags */}
                {plan.isMostPopular && (
                  <div className={classes.tag} aria-label="Most Popular">
                    <StarIcon className={classes.tagIcon} />
                    Most Popular
                  </div>
                )}
                {plan.isBestValue && (
                  <div
                    className={`${classes.tag} ${classes.bestValueTag}`}
                    aria-label="Best Value"
                  >
                    <TrendingUpIcon className={classes.tagIcon} />
                    Best Value
                  </div>
                )}

                <CardContent className={classes.cardContent}>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {plan.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    gutterBottom
                  >
                    {plan.description}
                  </Typography>
                  <Box className={classes.price}>
                    <Typography variant="h4" component="span">
                      ${plan.price}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="span"
                      color="textSecondary"
                      style={{ marginLeft: "4px" }}
                    >
                      /mo
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle1"
                    className={classes.subtitle1Enhanced}
                    gutterBottom
                  >
                    {plan.credits} MONTHLY GENERATION CREDITS
                  </Typography>
                  <Button
                    component={Link}
                    to={
                      auth.user
                        ? `/purchase/${plan.id}`
                        : `/auth/signup?next=/purchase/${plan.id}`
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    className={classes.button}
                    aria-label={`Choose ${plan.name} Plan`}
                  >
                    Choose {plan.name}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Free Trial Information */}
        <Box
          className={classes.freeTrialInfo}
          role="note"
          aria-label="Free Trial Information"
        >
          <InfoIcon className={classes.freeTrialIcon} />
          <Typography variant="body2">
            Start your <strong>7-day free trial</strong> today! No credit card
            required.
          </Typography>
        </Box>

        {/* All Plans Include */}
        <Box className={classes.featureCard}>
          <Typography
            variant="h5"
            component="h3"
            className={classes.featureTitle}
          >
            All Plans Include
          </Typography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            className={classes.featureGrid}
          >
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box className={classes.featureItem}>
                  <CheckCircleIcon
                    className={classes.featureIcon}
                    aria-hidden="true"
                  />
                  <Typography variant="body1">{feature}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Testimonials */}
        <Box className={classes.testimonialContainer}>
          <Typography
            variant="h5"
            component="h3"
            align="center"
            gutterBottom
            className={classes.featureTitle}
          >
            What Our Users Say
          </Typography>
          <Carousel
            autoPlay
            animation="slide"
            indicators={false}
            navButtonsAlwaysVisible={!isMobile}
            interval={6000}
            navButtonsProps={{
              style: {
                display: "none",
              },
            }}
            renderArrowPrev={(onClick, hasPrev) =>
              hasPrev && <CustomPrevButton onClick={onClick} />
            }
            renderArrowNext={(onClick, hasNext) =>
              hasNext && <CustomNextButton onClick={onClick} />
            }
            swipe={true}
            fullHeightHover={false}
          >
            {testimonials.map((testimonial, index) => (
              <Box
                key={index}
                role="region"
                aria-label={`Testimonial ${index + 1}`}
              >
                <Grid container alignItems="flex-start">
                  <Grid item>
                    <Avatar
                      src={testimonial.avatar}
                      alt={testimonial.author}
                      className={classes.avatar}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body1"
                      className={classes.testimonialText}
                    >
                      “{testimonial.text}”
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.testimonialAuthor}
                    >
                      - {testimonial.author}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Carousel>
        </Box>

        {/* Disclaimer */}
        <Typography className={classes.disclaimer}>
          Unused credits do not roll over. All Monthly subscriptions begin with
          a 7-day free trial. Cancel anytime.
        </Typography>
      </Container>
    </Section>
  );
}

export default PricingSection;
