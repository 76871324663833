// CoverLetter.js

import React from "react";
import { Box, Typography, Grid, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import DownloadButton from "./DownloadButton"; // Importing the DownloadButton component

const useStyles = makeStyles((theme) => ({
  // Include necessary styles
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.text.primary,
  },
  editableField: { marginBottom: theme.spacing(2) },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  narrowTextField: {
    maxWidth: 600,
    width: '100%',
  },
  signatureSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const CoverLetter = ({
  content,
  onContentChange,
  personalInformation,
  onPersonalInfoChange,
  handlePrintResume,
  downloading,
}) => {
  const classes = useStyles();

  if (!content || typeof content !== "object")
    return <Typography>Invalid cover letter data</Typography>;

  const handleContentChange = (field, value) => {
    onContentChange({ ...content, [field]: value });
  };

  return (
    <Box>
      {/* Header */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Header
        </Typography>
        <Grid container spacing={2}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              className={classes.editableField}
              value={personalInformation.firstName || ""}
              onChange={(e) =>
                onPersonalInfoChange("firstName", e.target.value)
              }
            />
          </Grid>
          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              className={classes.editableField}
              value={personalInformation.lastName || ""}
              onChange={(e) => onPersonalInfoChange("lastName", e.target.value)}
            />
          </Grid>
          {/* Address */}
          <Grid item xs={12}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              className={classes.editableField}
              value={personalInformation.address || ""}
              onChange={(e) => onPersonalInfoChange("address", e.target.value)}
            />
          </Grid>
          {/* Phone */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              className={classes.editableField}
              value={personalInformation.phone || ""}
              onChange={(e) => onPersonalInfoChange("phone", e.target.value)}
            />
          </Grid>
          {/* Email */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              className={classes.editableField}
              value={personalInformation.email || ""}
              onChange={(e) => onPersonalInfoChange("email", e.target.value)}
            />
          </Grid>
          {/* LinkedIn URL */}
          <Grid item xs={12}>
            <TextField
              label="LinkedIn URL"
              variant="outlined"
              fullWidth
              className={classes.editableField}
              value={personalInformation.linkedinUrl || ""}
              onChange={(e) =>
                onPersonalInfoChange("linkedinUrl", e.target.value)
              }
            />
          </Grid>
        </Grid>
      </Box>

      {/* Recipient Information */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Recipient Information
        </Typography>
        <TextField
          label="Recipient Name"
          variant="outlined"
          fullWidth
          className={`${classes.editableField} ${classes.narrowTextField}`}
          value={content.recipientName || ""}
          onChange={(e) => handleContentChange("recipientName", e.target.value)}
        />
        <TextField
          label="Recipient Title"
          variant="outlined"
          fullWidth
          className={`${classes.editableField} ${classes.narrowTextField}`}
          value={content.recipientTitle || ""}
          onChange={(e) =>
            handleContentChange("recipientTitle", e.target.value)
          }
        />
        <TextField
          label="Company Name"
          variant="outlined"
          fullWidth
          className={`${classes.editableField} ${classes.narrowTextField}`}
          value={content.companyName || ""}
          onChange={(e) => handleContentChange("companyName", e.target.value)}
        />
      </Box>

      {/* Greeting */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Greeting
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          className={classes.editableField}
          value={content.greeting || ""}
          onChange={(e) => handleContentChange("greeting", e.target.value)}
        />
      </Box>

      {/* Opening Paragraph */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Opening Paragraph
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          className={classes.editableField}
          value={content.openingParagraph || ""}
          onChange={(e) =>
            handleContentChange("openingParagraph", e.target.value)
          }
        />
      </Box>

      {/* Body Paragraphs */}
      {content.bodyParagraphs && content.bodyParagraphs.length > 0 && (
        <Box className={classes.sectionContainer}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Body Paragraphs
          </Typography>
          {content.bodyParagraphs.map((para, index) => (
            <TextField
              key={index}
              variant="outlined"
              fullWidth
              multiline
              minRows={3}
              className={classes.editableField}
              value={para || ""}
              onChange={(e) => {
                const updatedBodyParagraphs = [...content.bodyParagraphs];
                updatedBodyParagraphs[index] = e.target.value;
                handleContentChange("bodyParagraphs", updatedBodyParagraphs);
              }}
            />
          ))}
        </Box>
      )}

      {/* Closing Paragraph */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Closing Paragraph
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          className={classes.editableField}
          value={content.closingParagraph || ""}
          onChange={(e) =>
            handleContentChange("closingParagraph", e.target.value)
          }
        />
      </Box>

      {/* Signature */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Signature
        </Typography>
        <TextField
          label="Closing Phrase"
          variant="outlined"
          fullWidth
          className={classes.editableField}
          value={content.closingPhrase || "Sincerely,"}
          onChange={(e) => handleContentChange("closingPhrase", e.target.value)}
        />
        <Box className={classes.signatureSpacing} />
        <TextField
          label="Your Name"
          variant="outlined"
          fullWidth
          className={classes.editableField}
          value={
            content.signatureName ||
            `${personalInformation.firstName} ${personalInformation.lastName}`
          }
          onChange={(e) => handleContentChange("signatureName", e.target.value)}
        />
      </Box>

      {/* Download Button */}
      <Box className={classes.buttonGroup}>
        <DownloadButton
          onClick={() =>
            handlePrintResume(
              content,
              "cover_letter_template_1.docx",
              "cover_letter.docx",
              { personalInformation: personalInformation }
            )
          }
          downloading={downloading}
          label="Download Cover Letter"
          startIcon={<GetAppIcon />}
        />
      </Box>
    </Box>
  );
};

export default CoverLetter;
