// src/util/prices.js

const stripePriceIds = {
  basic:
    process.env.STRIPE_PRICE_BASIC || process.env.REACT_APP_STRIPE_PRICE_BASIC,
  professional:
    process.env.REACT_APP_STRIPE_PRICE_PROFESSIONAL ||
    process.env.REACT_APP_STRIPE_PRICE_PROFESSIONAL,
  premium:
    process.env.STRIPE_PRICE_PREMIUM ||
    process.env.REACT_APP_STRIPE_PRICE_PREMIUM,
};

console.log("Initialized stripePriceIds:", stripePriceIds);

/**
 * Retrieves the Stripe Price ID for a given plan.
 * @param {string} planId - The ID of the plan (e.g., 'basic', 'professional', 'premium').
 * @returns {string|undefined} The corresponding Stripe Price ID.
 */
function getStripePriceId(planId) {
  console.log(`getStripePriceId called with planId: '${planId}'`);
  const priceId = stripePriceIds[planId];
  if (priceId) {
    // console.log(`Found Stripe Price ID for plan '${planId}': ${priceId}`);
  } else {
    // console.warn(`No Stripe Price ID found for plan '${planId}'`);
  }
  return priceId;
}

/**
 * Retrieves the friendly plan ID (e.g., 'basic', 'professional', 'premium') based on a Stripe Price ID.
 * @param {string} stripePriceId - The Stripe Price ID.
 * @returns {string|undefined} The corresponding friendly plan ID.
 */
function getFriendlyPlanId(stripePriceId) {
  // console.log(
  //   `getFriendlyPlanId called with stripePriceId: '${stripePriceId}'`
  // );
  const planId = Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
  if (planId) {
    // console.log(
    //   `Found plan ID '${planId}' for Stripe Price ID '${stripePriceId}'`
    // );
  } else {
    // console.warn(`No plan ID found for Stripe Price ID '${stripePriceId}'`);
  }
  return planId;
}

/**
 * Retrieves the number of credits associated with a given plan.
 * @param {string} planId - The ID of the plan.
 * @returns {number} The number of credits for the plan, or 0 if the plan ID is invalid.
 */
function getPlanCredits(planId) {
  // console.log(`getPlanCredits called with planId: '${planId}'`);
  const planCredits = {
    basic: 100,
    professional: 300,
    premium: 1000,
  };
  const credits = planCredits[planId];
  if (credits !== undefined) {
    // console.log(`Plan '${planId}' has ${credits} credits`);
  } else {
    // console.warn(`Invalid planId '${planId}'. Returning 0 credits.`);
  }
  return credits || 0;
}

module.exports = {
  getStripePriceId,
  getFriendlyPlanId,
  getPlanCredits,
};
