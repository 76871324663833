// src/components/Meta.jsx

import React from "react";
import { Helmet } from "react-helmet";
import { useRouter } from "./../util/router.js";
import meta_image from "./../images/Hero_image.png";

function Meta(props) {
  const { children, ...customPageMeta } = props;
  const router = useRouter();

  const globalMeta = {
    siteName: "Resume Revival",
    domain: "https://www.resumerevival.xyz",
    twitterHandle: "@Resume_Revival",
    facebookPage: "https://www.facebook.com/ResumeRevival",
    linkedinProfile: "https://www.linkedin.com/company/resume-revival",
    instagramProfile: "https://www.instagram.com/resume_revival",
  };

  // Default meta values for current page (override with props)
  const defaultPageMeta = {
    // Page title
    title: "Resume Revival App",
    // Page description
    description:
      "Streamline your job hunt with Resume Revival – your free resume builder powered by AI. Tailor unique resumes and cover letters to match any job description. Perform skill gap analysis to boost your professional growth. ATS-optimized and keyword-rich applications. Free. Effective. User-friendly.",
    // Social share image
    image: meta_image,
    // Page type
    type: "website",
    // Keywords for SEO
    keywords:
      "AI job applications, resume builder, cover letter generator, job search acceleration",
  };

  // Merge global, default, and custom meta
  const meta = { ...globalMeta, ...defaultPageMeta, ...customPageMeta };

  // Ensure image is a string URL
  const imageUrl = meta.image
    ? typeof meta.image === "string"
      ? meta.image
      : meta.image.src
    : `${meta.domain}/default-image.jpg`; // Fallback image

  // Construct full URL
  const currentUrl = `${meta.domain}${router.pathname}${router.location.search}`;

  // JSON-LD Structured Data for Organization
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": meta.domain,
    "name": meta.siteName,
    "logo": `${meta.domain}/logo.png`,
    "sameAs": [
      meta.twitterHandle,
      meta.facebookPage,
      meta.linkedinProfile,
      meta.instagramProfile,
      // Add other social profiles if available
    ],
    "description": meta.description,
  };

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} key="description" />
      {meta.keywords && <meta name="keywords" content={meta.keywords} key="keywords" />}
      <link rel="canonical" href={currentUrl} key="canonical" />
      <meta name="ir-site-verification-token" content="759085616" />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={meta.title} key="og-title" />
      <meta property="og:description" content={meta.description} key="og-description" />
      <meta property="og:site_name" content={meta.siteName} key="og-site-name" />
      <meta property="og:type" content={meta.type} key="og-type" />
      <meta property="og:url" content={currentUrl} key="og-url" />
      <meta property="og:image" content={imageUrl} key="og-image" />
      <meta property="og:image:alt" content={meta.title} key="og-image-alt" />
      <meta property="og:image:type" content="image/png" key="og-image-type" />
      <meta property="og:image:width" content="1200" key="og-image-width" />
      <meta property="og:image:height" content="630" key="og-image-height" />

      {/* Twitter */}
      <meta name="twitter:title" content={meta.title} key="twitter-title" />
      <meta name="twitter:description" content={meta.description} key="twitter-description" />
      <meta name="twitter:card" content="summary_large_image" key="twitter-card" />
      <meta name="twitter:site" content={meta.twitterHandle} key="twitter-site" />
      <meta name="twitter:image" content={imageUrl} key="twitter-image" />
      <meta name="twitter:image:alt" content={meta.title} key="twitter-image-alt" />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" key="robots" />
      <meta name="theme-color" content="#ffffff" key="theme-color" />

      {/* Favicon */}
      <link rel="icon" href="/favicon.ico" />

      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>

      {/* Add more meta tags as needed */}
      {children}
    </Helmet>
  );
}

export default Meta;
