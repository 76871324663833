// src/components/ErrorBoundary.jsx

import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

/**
 * ErrorBoundary Component to catch errors in child components.
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  handleRetry = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { children } = this.props;
    if (this.state.hasError) {
      return (
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={this.handleRetry}>
              Retry
            </Button>
          }
          aria-live="assertive"
        >
          Something went wrong. Please try again.
        </Alert>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
