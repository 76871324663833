import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  summaryContent: {
    margin: "0 !important",
  },
  card: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.default,
  },
  cardContent: {
    padding: theme.spacing(2),
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "Q: What is Resume Revival?",
      answer:
        "A: Resume Revival is a premium, AI-driven platform designed to empower job seekers by creating tailored, professional application materials. Beyond resumes and cover letters, we offer a suite of tools including LinkedIn profile optimization, professional bio creation, interview preparation resources, and more to enhance your entire job application process.",
    },
    {
      question: "Q: How does Resume Revival enhance my job applications?",
      answer:
        "A: Resume Revival leverages advanced AI to analyze your professional background and align it with your target job descriptions. We generate keyword-rich, ATS-friendly resumes and cover letters, optimize your LinkedIn profile, and provide additional materials such as professional bios and personalized interview preparation guides to ensure a cohesive and compelling application package.",
    },
    {
      question: "Q: What types of application materials can Resume Revival generate?",
      answer:
        "A: In addition to customized resumes and cover letters, Resume Revival offers a variety of application materials including professional bios, LinkedIn profile enhancements, interview preparation guides, networking email templates, career development plans, and company research reports, all designed to present you as a standout candidate.",
    },
    {
      question: "Q: How secure is my data with Resume Revival?",
      answer:
        "A: At Resume Revival, your privacy and data security are paramount. We employ robust security measures to ensure that your professional information is protected and used solely to generate your application materials. Your data is never shared with third parties without your explicit consent.",
    },
    {
      question: "Q: Can I customize the design and layout of my application materials?",
      answer:
        "A: Absolutely. Resume Revival offers a selection of stylish, modern templates that you can customize to reflect your personal brand and the industry you're targeting. Our designs emphasize a clean, professional aesthetic to ensure your materials make a strong visual impact.",
    },
    {
      question: "Q: What does 'ATS-friendly' mean, and why is it important?",
      answer:
        "A: 'ATS-friendly' refers to the formatting and keyword optimization of your resumes and cover letters to ensure they can be easily parsed by Applicant Tracking Systems (ATS) used by many employers. This increases the likelihood that your application will be seen by human recruiters.",
    },
    {
      question: "Q: How does Resume Revival support my LinkedIn profile optimization?",
      answer:
        "A: Resume Revival enhances your LinkedIn profile by crafting compelling summaries, optimizing job descriptions with relevant keywords, and ensuring consistency with your resume. This comprehensive approach helps attract recruiters and build a strong professional online presence.",
    },
    {
      question: "Q: What interview preparation resources does Resume Revival provide?",
      answer:
        "A: We offer personalized interview preparation resources, including customized interview questions, expert tips on answering common queries, and strategies to effectively communicate your skills and experiences. These tools are designed to help you confidently navigate the interview process.",
    },
    {
      question: "Q: Can Resume Revival assist with personal branding?",
      answer:
        "A: Yes, Resume Revival provides personal branding tips and professional bio creation to help you establish a strong, consistent personal brand across all your application materials and online profiles, making you more memorable to potential employers.",
    },
    {
      question: "Q: How does Resume Revival handle multiple job applications?",
      answer:
        "A: Resume Revival allows you to efficiently customize your resumes, cover letters, and other application materials for multiple job roles simultaneously. This ensures each application is tailored to the specific job requirements, increasing your chances of success across various opportunities.",
    },
    {
      question: "Q: What additional services does Resume Revival offer to support my job search?",
      answer:
        "A: Beyond core application materials, Resume Revival offers services such as networking email templates, career development plans, job application follow-up emails, and comprehensive company research reports. These resources are designed to support every stage of your job search journey.",
    },
    {
      question: "Q: What makes Resume Revival different from other resume builders?",
      answer:
        "A: Resume Revival stands out with its comprehensive suite of application tools, high-end, customizable templates, and AI-driven personalization. Our focus on a clean, modern aesthetic and a thoughtful user experience ensures that your application materials not only perform well but also make a lasting impression.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
              id={`faq-header-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Card className={classes.card} elevation={0}>
                <CardContent className={classes.cardContent}>
                  <Typography>{item.answer}</Typography>
                </CardContent>
              </Card>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
