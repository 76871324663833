// DownloadButton.js

import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

const DownloadButton = ({ onClick, downloading, label, startIcon }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={startIcon}
      onClick={onClick}
      disabled={downloading}
    >
      {downloading ? <CircularProgress size={24} color="inherit" /> : label}
    </Button>
  );
};

export default DownloadButton;
