// ProfessionalBio.js

import React from "react";
import { Box, Typography, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// You may need to define or import the updateNestedObject function
const updateNestedObject = (obj, pathArray, value) => {
  if (pathArray.length === 1) {
    return { ...obj, [pathArray[0]]: value };
  }
  const [firstKey, ...restKeys] = pathArray;
  return {
    ...obj,
    [firstKey]: updateNestedObject(obj[firstKey] || {}, restKeys, value),
  };
};

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  editableField: { marginBottom: theme.spacing(2) },
  boldText: { fontWeight: "bold" },
  bulletItem: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  bulletTextField: {
    flexGrow: 1,
  },
}));

const ProfessionalBio = ({ content, onContentChange }) => {
  const classes = useStyles();

  if (!content || typeof content !== "object")
    return <Typography>Invalid professional bio data</Typography>;

  const handleFieldChange = (path, value) => {
    const updatedContent = updateNestedObject(content, path, value);
    onContentChange(updatedContent);
  };

  return (
    <Box>
      {/* Name and Title */}
      <Box className={classes.sectionContainer}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          className={classes.editableField}
          value={content.name || ""}
          onChange={(e) => handleFieldChange(["name"], e.target.value)}
        />
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          className={classes.editableField}
          value={content.title || ""}
          onChange={(e) => handleFieldChange(["title"], e.target.value)}
        />
      </Box>

      {/* Summary */}
      <Box className={classes.sectionContainer}>
        <Typography className={classes.boldText}>Summary:</Typography>
        <TextField
          label="Summary"
          variant="outlined"
          fullWidth
          multiline
          minRows={3}
          className={classes.editableField}
          value={content.summary || ""}
          onChange={(e) => handleFieldChange(["summary"], e.target.value)}
        />
      </Box>

      {/* Experience */}
      <Box className={classes.sectionContainer}>
        <Typography className={classes.boldText}>Experience:</Typography>
        {content.experience && content.experience.length > 0 ? (
          content.experience.map((exp, index) => (
            <Box key={index} className={classes.sectionContainer}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Job Title"
                    variant="outlined"
                    fullWidth
                    className={classes.editableField}
                    value={exp.jobTitle || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["experience", index, "jobTitle"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    className={classes.editableField}
                    value={exp.companyName || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["experience", index, "companyName"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Location"
                    variant="outlined"
                    fullWidth
                    className={classes.editableField}
                    value={exp.location || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["experience", index, "location"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Start Date"
                    variant="outlined"
                    type="date"
                    className={classes.editableField}
                    InputLabelProps={{ shrink: true }}
                    value={exp.startDate || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["experience", index, "startDate"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="End Date"
                    variant="outlined"
                    type="date"
                    className={classes.editableField}
                    InputLabelProps={{ shrink: true }}
                    value={exp.endDate || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["experience", index, "endDate"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </Grid>

              {/* Responsibilities */}
              <Box mt={2}>
                <Typography className={classes.boldText}>
                  Responsibilities:
                </Typography>
                {exp.responsibilities &&
                  exp.responsibilities.map((resp, respIndex) => (
                    <Box key={respIndex} className={classes.bulletItem}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        className={classes.bulletTextField}
                        value={resp || ""}
                        onChange={(e) =>
                          handleFieldChange(
                            [
                              "experience",
                              index,
                              "responsibilities",
                              respIndex,
                            ],
                            e.target.value
                          )
                        }
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
          ))
        ) : (
          <Typography>No experience details provided.</Typography>
        )}
      </Box>

      {/* Education */}
      <Box className={classes.sectionContainer}>
        <Typography className={classes.boldText}>Education:</Typography>
        {content.education && content.education.length > 0 ? (
          content.education.map((edu, index) => (
            <Box key={index} className={classes.sectionContainer}>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Degree"
                    variant="outlined"
                    fullWidth
                    className={classes.editableField}
                    value={edu.degree || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["education", index, "degree"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Institution"
                    variant="outlined"
                    fullWidth
                    className={classes.editableField}
                    value={edu.institution || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["education", index, "institution"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Location"
                    variant="outlined"
                    fullWidth
                    className={classes.editableField}
                    value={edu.location || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["education", index, "location"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Graduation Date"
                    variant="outlined"
                    type="date"
                    className={classes.editableField}
                    InputLabelProps={{ shrink: true }}
                    value={edu.graduationDate || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["education", index, "graduationDate"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <Typography>No education details provided.</Typography>
        )}
      </Box>

      {/* Skills */}
      <Box className={classes.sectionContainer}>
        <Typography className={classes.boldText}>Skills:</Typography>
        <TextField
          label="Skills (comma separated)"
          variant="outlined"
          fullWidth
          className={classes.editableField}
          value={content.skills ? content.skills.join(", ") : ""}
          onChange={(e) =>
            handleFieldChange(
              ["skills"],
              e.target.value.split(",").map((s) => s.trim())
            )
          }
        />
      </Box>
    </Box>
  );
};

export default ProfessionalBio;
