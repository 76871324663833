// Resume.js

import React from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Divider,
  List,
  ListItem,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  Person as PersonIcon,
  Work as WorkIcon,
  School as SchoolIcon,
  Star as StarIcon,
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import DownloadButton from "./DownloadButton"; // Importing the DownloadButton component

const useStyles = makeStyles((theme) => ({
  // Include all the styles needed
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.text.primary,
  },
  editableField: { marginBottom: theme.spacing(2) },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  bulletList: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
  bulletItem: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  bullet: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: "50%",
    backgroundColor: theme.palette.text.primary,
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
  bulletTextField: {
    flexGrow: 1,
  },
  fieldLabel: { fontWeight: "bold", marginBottom: theme.spacing(0.5) },
}));

const updateNestedObject = (obj, pathArray, value) => {
  if (pathArray.length === 1) {
    if (Array.isArray(obj)) {
      const index = parseInt(pathArray[0], 10);
      const newArr = obj.slice();
      newArr[index] = value;
      return newArr;
    } else {
      return {
        ...obj,
        [pathArray[0]]: value,
      };
    }
  }

  const [firstKey, ...restKeys] = pathArray;

  if (Array.isArray(obj)) {
    const index = parseInt(firstKey, 10);
    const newArr = obj.slice();

    const nextObj = obj[index] !== undefined ? obj[index] : {};
    newArr[index] = updateNestedObject(nextObj, restKeys, value);
    return newArr;
  } else {
    return {
      ...obj,
      [firstKey]: updateNestedObject(obj[firstKey] || {}, restKeys, value),
    };
  }
};

const Resume = ({
  content,
  onContentChange,
  handlePrintResume,
  downloading,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  if (!content || typeof content !== "object")
    return <Typography>Invalid resume data</Typography>;

  const handleFieldChange = (path, value) => {
    const updatedContent = updateNestedObject(content, path, value);
    onContentChange(updatedContent);
  };

  return (
    <Box>
      {/* Personal Information */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          <Box display="flex" alignItems="center">
            <PersonIcon
              color="primary"
              style={{ marginRight: theme.spacing(1) }}
            />
            Personal Information
          </Box>
        </Typography>
        <Grid container spacing={2}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              multiline
              minRows={1} // Changed from rows to minRows
              maxRows={4}
              className={classes.editableField}
              value={content.personalInformation.firstName || ""}
              onChange={(e) =>
                handleFieldChange(
                  ["personalInformation", "firstName"],
                  e.target.value
                )
              }
            />
          </Grid>
          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              multiline
              minRows={1} // Changed from rows to minRows
              maxRows={4}
              className={classes.editableField}
              value={content.personalInformation.lastName || ""}
              onChange={(e) =>
                handleFieldChange(
                  ["personalInformation", "lastName"],
                  e.target.value
                )
              }
            />
          </Grid>
          {/* Email */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              multiline
              minRows={1} // Changed from rows to minRows
              maxRows={4}
              className={classes.editableField}
              value={content.personalInformation.email || ""}
              onChange={(e) =>
                handleFieldChange(
                  ["personalInformation", "email"],
                  e.target.value
                )
              }
            />
          </Grid>
          {/* Phone */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              multiline
              minRows={1} // Changed from rows to minRows
              maxRows={4}
              className={classes.editableField}
              value={content.personalInformation.phone || ""}
              onChange={(e) =>
                handleFieldChange(
                  ["personalInformation", "phone"],
                  e.target.value
                )
              }
            />
          </Grid>
          {/* Address */}
          <Grid item xs={12}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              multiline
              minRows={2} // Changed from rows to minRows
              maxRows={6}
              className={classes.editableField}
              value={content.personalInformation.address || ""}
              onChange={(e) =>
                handleFieldChange(
                  ["personalInformation", "address"],
                  e.target.value
                )
              }
            />
          </Grid>
          {/* LinkedIn URL */}
          <Grid item xs={12}>
            <TextField
              label="LinkedIn URL"
              variant="outlined"
              fullWidth
              multiline
              minRows={1} // Changed from rows to minRows
              maxRows={4}
              className={classes.editableField}
              value={content.personalInformation.linkedinUrl || ""}
              onChange={(e) =>
                handleFieldChange(
                  ["personalInformation", "linkedinUrl"],
                  e.target.value
                )
              }
            />
          </Grid>
        </Grid>
      </Box>

      {/* Professional Summary */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Professional Summary
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          minRows={3} // Changed from rows to minRows
          maxRows={10}
          className={classes.editableField}
          value={content.personalInformation.professionalSummary || ""}
          onChange={(e) =>
            handleFieldChange(
              ["personalInformation", "professionalSummary"],
              e.target.value
            )
          }
        />
      </Box>

      {/* Work Experience */}
      {content.workExperience && content.workExperience.length > 0 && (
        <Box className={classes.sectionContainer}>
          <Typography variant="h5" className={classes.sectionTitle}>
            <Box display="flex" alignItems="center">
              <WorkIcon
                color="primary"
                style={{ marginRight: theme.spacing(1) }}
              />
              Work Experience
            </Box>
          </Typography>
          {content.workExperience.map((job, index) => (
            <Box key={index} className={classes.workExperienceItem}>
              {index > 0 && <Divider className={classes.divider} />}
              <Grid container spacing={2}>
                {/* Job Title */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Job Title"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={1} // Changed from rows to minRows
                    maxRows={4}
                    className={classes.editableField}
                    value={job.jobTitle || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["workExperience", index, "jobTitle"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                {/* Company Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={1} // Changed from rows to minRows
                    maxRows={4}
                    className={classes.editableField}
                    value={job.companyName || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["workExperience", index, "companyName"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                {/* Location */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Location"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={1} // Changed from rows to minRows
                    maxRows={4}
                    className={classes.editableField}
                    value={job.location || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["workExperience", index, "location"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                {/* Start Date */}
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Start Date"
                    variant="outlined"
                    type="date"
                    className={classes.editableField}
                    InputLabelProps={{ shrink: true }}
                    value={job.startDate || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["workExperience", index, "startDate"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                {/* End Date */}
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="End Date"
                    variant="outlined"
                    type="date"
                    className={classes.editableField}
                    InputLabelProps={{ shrink: true }}
                    value={job.endDate || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["workExperience", index, "endDate"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </Grid>
              {/* Responsibilities */}
              <Typography className={classes.fieldLabel}>
                Responsibilities:
              </Typography>
              <List className={classes.bulletList}>
                {job.responsibilities.map((resp, idx) => (
                  <ListItem key={idx} className={classes.bulletItem}>
                    <div className={classes.bullet}></div>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={1} // Changed from rows to minRows
                      maxRows={6}
                      className={classes.bulletTextField}
                      value={resp || ""}
                      onChange={(e) =>
                        handleFieldChange(
                          ["workExperience", index, "responsibilities", idx],
                          e.target.value
                        )
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      )}

      {/* Education */}
      {content.education && content.education.length > 0 && (
        <Box className={classes.sectionContainer}>
          <Typography variant="h5" className={classes.sectionTitle}>
            <Box display="flex" alignItems="center">
              <SchoolIcon
                color="primary"
                style={{ marginRight: theme.spacing(1) }}
              />
              Education
            </Box>
          </Typography>
          {content.education.map((edu, index) => (
            <Box key={index}>
              {index > 0 && <Divider className={classes.divider} />}
              <Grid container spacing={2}>
                {/* Degree */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Degree"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={1} // Changed from rows to minRows
                    maxRows={4}
                    className={classes.editableField}
                    value={edu.degree || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["education", index, "degree"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                {/* Institution */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Institution"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={1} // Changed from rows to minRows
                    maxRows={4}
                    className={classes.editableField}
                    value={edu.institution || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["education", index, "institution"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                {/* Location */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Location"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={1} // Changed from rows to minRows
                    maxRows={4}
                    className={classes.editableField}
                    value={edu.location || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["education", index, "location"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
                {/* Graduation Date */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Graduation Date"
                    variant="outlined"
                    type="date"
                    className={classes.editableField}
                    InputLabelProps={{ shrink: true }}
                    value={edu.graduationDate || ""}
                    onChange={(e) =>
                      handleFieldChange(
                        ["education", index, "graduationDate"],
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      )}

      {/* Skills */}
      <Box className={classes.sectionContainer}>
        <Typography variant="h5" className={classes.sectionTitle}>
          <Box display="flex" alignItems="center">
            <StarIcon
              color="primary"
              style={{ marginRight: theme.spacing(1) }}
            />
            Skills
          </Box>
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          minRows={1} // Changed from rows to minRows
          maxRows={4}
          className={classes.editableField}
          value={content.skills.join(", ") || ""}
          onChange={(e) =>
            handleFieldChange(
              ["skills"],
              e.target.value.split(",").map((s) => s.trim())
            )
          }
        />
      </Box>

      {/* Download Button */}
      <Box className={classes.buttonGroup}>
        <DownloadButton
          onClick={() =>
            handlePrintResume(content, "resume_template_1.docx", "resume.docx")
          }
          downloading={downloading}
          label="Download Resume"
          startIcon={<GetAppIcon />}
        />
      </Box>
    </Box>
  );
};

export default Resume;
