// MessageToRecruiter.js

import React from "react";
import { Box, Typography, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  boldText: { fontWeight: "bold" },
  editableField: { marginBottom: theme.spacing(2) },
}));

const MessageToRecruiter = ({ content, onContentChange, title }) => {
  const classes = useStyles();

  const handleSendEmail = () => {
    const subject = encodeURIComponent(content.subject || "");
    const body = encodeURIComponent(content.body || "");
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
  };

  const handleFieldChange = (field, value) => {
    const updatedContent = { ...content };
    updatedContent[field] = value;
    onContentChange(updatedContent);
  };

  return (
    <Box>

      {/* Subject Section */}
      <Box className={classes.sectionContainer}>
        <Typography className={classes.boldText}>Subject:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          className={classes.editableField}
          value={content.subject || ""}
          onChange={(e) => handleFieldChange("subject", e.target.value)}
        />
      </Box>

      {/* Body Section */}
      <Box className={classes.sectionContainer}>
        <Typography className={classes.boldText}>Body:</Typography>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          minRows={4}
          className={classes.editableField}
          value={content.body || ""}
          onChange={(e) => handleFieldChange("body", e.target.value)}
        />
      </Box>

      {/* Button to send email */}
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<MailOutlineIcon />}
          onClick={handleSendEmail}
        >
          Send Email
        </Button>
      </Box>
    </Box>
  );
};

export default MessageToRecruiter;
