// SkillGapAnalysis.js

import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  boldText: { fontWeight: "bold" },
  listItem: {
    paddingLeft: 0,
  },
}));

const SkillGapAnalysis = ({ content }) => {
  const classes = useStyles();

  if (!content || typeof content !== "object")
    return <Typography>Invalid skill gap analysis data</Typography>;

  const renderList = (items) => (
    <List>
      {items.map((item, index) => (
        <ListItem key={index} className={classes.listItem}>
          <ListItemText primary={`• ${item}`} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box>
      <Typography className={classes.boldText}>Identified Gaps:</Typography>
      {renderList(content.identifiedGaps)}
      <Typography className={classes.boldText}>Suggestions:</Typography>
      {renderList(content.suggestions)}
    </Box>
  );
};

export default SkillGapAnalysis;
