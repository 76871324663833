// src/pages/PricingPage.jsx

import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Upgrade Your Job Search"
        subtitle="Choose the plan that fits your needs and accelerate your career."
      />
    </>
  );
}

export default PricingPage;
