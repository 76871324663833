// src/components/Navbar.jsx

import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useDarkMode } from "./../util/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  accountButton: {
    // Apply marginTop only on mobile devices (max-width:600px)
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
}));

function Navbar(props) {
  const classes = useStyles();

  const auth = useAuth();
  const darkMode = useDarkMode();
  const [menuState, setMenuState] = useState(null);

  // Detect if the window width is below 1275px
  const isBelow1275 = useMediaQuery("(max-width:1275px)");
  // Detect if the window width is below 600px (mobile)
  const isMobile = useMediaQuery("(max-width:600px)");

  // Use inverted logo if specified and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to) and the menu id
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  return (
    <>
      {/* Fixed AppBar */}
      <AppBar position="fixed" color="default" elevation={0}>
        <Container disableGutters={true}>
          <Toolbar>
            {/* Spacer on the left for alignment */}
            <div className={classes.spacer} />

            {/* Account Dropdown */}
            {auth.user ? (
              <Button
                color="inherit"
                aria-label="Account"
                aria-controls="account-menu"
                aria-haspopup="true"
                onClick={(event) => handleOpenMenu(event, "account-menu")}
                className={classes.accountButton} // Apply the responsive class
              >
                Account
                <ExpandMoreIcon />
              </Button>
            ) : (
              <Button
                component={Link}
                to="/auth/signin"
                color="inherit"
                className={classes.accountButton} // Apply the responsive class
              >
                Sign in
              </Button>
            )}

            <Menu
              id="account-menu"
              open={Boolean(menuState && menuState.id === "account-menu")}
              anchorEl={menuState ? menuState.anchor : null}
              getContentAnchorEl={null}
              onClick={handleCloseMenu}
              onClose={handleCloseMenu}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {auth.user &&
                [
                  <MenuItem key="dashboard" component={Link} to="/dashboard">
                    Dashboard
                  </MenuItem>,
                  <MenuItem
                    key="settings"
                    component={Link}
                    to="/settings/general"
                  >
                    Settings
                  </MenuItem>,
                  <Divider key="divider1" />,
                  isBelow1275 ? (
                    <MenuItem key="darkmode" onClick={darkMode.toggle}>
                      {darkMode.value ? "Dark Mode" : "Light Mode"}
                      {darkMode.value ? (
                        <NightsStayIcon style={{ marginLeft: 8 }} />
                      ) : (
                        <WbSunnyIcon style={{ marginLeft: 8 }} />
                      )}
                    </MenuItem>
                  ) : null,
                  <Divider key="divider2" />,
                  <MenuItem
                    key="signout"
                    onClick={() => {
                      auth.signout();
                    }}
                  >
                    Sign out
                  </MenuItem>,
                ].filter(Boolean)}
            </Menu>

            {/* Dark Mode Toggle for Desktop */}
            {!isBelow1275 && (
              <IconButton
                color="inherit"
                onClick={darkMode.toggle}
                style={{ opacity: 0.6 }}
              >
                {darkMode.value ? <NightsStayIcon /> : <WbSunnyIcon />}
              </IconButton>
            )}

            {/* Logo on the Right */}
            <Link to="/">
              <img
                src={logo}
                alt="Resume Revival Logo"
                className={classes.logo}
                style={{
                  width: isMobile ? "50%" : isBelow1275 ? "70%" : "auto",
                  height: "auto",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
              />
            </Link>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Navbar;
