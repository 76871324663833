// src/components/FormSectionSubHeader.js

import React from "react";
import PropTypes from "prop-types";
import { Typography, Divider, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  subHeaderContainer: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  subHeaderText: {
    fontWeight: 600,
    fontSize: "1.3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

/**
 * FormSectionSubHeader Component
 * Renders a subheader with a title and a divider.
 * This subheader is hidden on small and extra-small screens (mobile devices).
 *
 * @param {string} title - The text to display as the subheader.
 */
const FormSectionSubHeader = ({ title }) => {
  const classes = useStyles();

  return (
    <Hidden smDown>
      <div className={classes.subHeaderContainer}>
        <Typography
          variant="h6"
          className={classes.subHeaderText}
          align="center"
        >
          {title}
        </Typography>
        <Divider className={classes.divider} />
      </div>
    </Hidden>
  );
};

FormSectionSubHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FormSectionSubHeader;
