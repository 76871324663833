// db.js

import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { firebaseApp } from "./firebase";

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data using React Query and Firestore
export function useUser(uid) {
  return useQuery(
    ["user", { uid }],
    () => getDoc(doc(db, "users", uid)).then(format),
    { enabled: !!uid }
  );
}

// Fetch user data once (non-hook)
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user or create if it doesn't exist
export function updateUser(uid, data) {
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Get user by stripeCustomerId
export function getUserByCustomerId(customerId) {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("stripeCustomerId", "==", customerId));

  return getDocs(q).then((querySnapshot) => {
    if (!querySnapshot.empty) {
      // Return the first matching user
      return format(querySnapshot.docs[0]);
    } else {
      return null;
    }
  });
}

// Update user by stripeCustomerId
export function updateUserByCustomerId(customerId, data) {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("stripeCustomerId", "==", customerId));

  return getDocs(q).then((querySnapshot) => {
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return setDoc(userDoc.ref, data, { merge: true });
    } else {
      throw new Error(`No user found with stripeCustomerId: ${customerId}`);
    }
  });
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs, we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // On subsequent runs, we invalidate the query so that it re-fetches if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    if (unsubs[queryHash]) {
      unsubs[queryHash]();
    }
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

// Subscribe to data for a specific user in a specific collection
export function useUserDataInCollection(uid, collectionName) {
  return useQuery(
    ["user", { uid }, collectionName],
    createQuery(() => collection(db, "users", uid, collectionName)),
    { enabled: !!uid }
  );
}

// Fetch data for a specific user once given uid and collection name
export function getUserDataInCollection(uid, collectionName) {
  return getDocs(collection(db, "users", uid, collectionName)).then(
    (querySnapshot) => {
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });
      return userData;
    }
  );
}

// Add a new document for a specific user in a specific collection
export function addUserDocument(uid, collectionName, data) {
  return addDoc(collection(db, "users", uid, collectionName), data);
}

// Update specified data for a specific user in a specific collection
export function updateUserDocument(uid, collectionName, docId, data) {
  return updateDoc(doc(db, "users", uid, collectionName, docId), data);
}

// Delete specified data for a specific user in a specific collection
export function deleteUserDocument(uid, collectionName, docId) {
  return deleteDoc(doc(db, "users", uid, collectionName, docId));
}

// Export all functions
export {
  db,
  client,
  createQuery,
  format,
  unsubs,
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProviderBase,
};
