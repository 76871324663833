// src/components/PersonalInformation.js

import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  memo,
} from "react";
import PropTypes from "prop-types";
import { FormContext } from "./FormContext";
import {
  Grid,
  Paper,
  Typography,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  LinkedIn as LinkedInIcon,
  Language as LanguageIcon,
  Twitter as TwitterIcon,
  GitHub as GitHubIcon,
  Person as PersonIcon,
  Home as HomeIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Work as WorkIcon,
} from "@material-ui/icons";
import { FormField } from "./FormComponents";

// Firestore imports (Firebase v9 Modular)
import { db } from "../util/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Auth import
import { useAuth } from "../util/auth";

// Utility imports
import debounce from "lodash.debounce";

// Import Yup validation schema
import { validationSchema } from "./validationSchema";

// Import the new FormSectionSubHeader component
import FormSectionSubHeader from "./FormSectionSubHeader";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  formPaper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
  },
  formSection: {
    marginBottom: theme.spacing(2),
  },
  subsectionHeader: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

// SubsectionHeader Component (Retained from original code)
const SubsectionHeader = memo(({ title }) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.subsectionHeader}>
      {title}
    </Typography>
  );
});

SubsectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

// Define form fields structure
const formFields = [
  {
    section: "Name",
    fields: [
      {
        name: "firstName",
        label: "First Name",
        required: true,
        icon: PersonIcon,
      },
      { name: "lastName", label: "Last Name", required: true },
    ],
  },
  {
    section: "Address",
    fields: [
      {
        name: "streetAddress",
        label: "Street Address",
        required: true,
        icon: HomeIcon,
        fullWidth: true,
      },
      { name: "city", label: "City", required: true },
      { name: "state", label: "State/Province", required: true },
      { name: "zipCode", label: "Zip/Postal Code", required: true },
      { name: "country", label: "Country", required: true },
    ],
  },
  {
    section: "Contact Information",
    fields: [
      {
        name: "phone",
        label: "Phone",
        required: true,
        type: "tel",
        icon: PhoneIcon,
      },
      {
        name: "email",
        label: "Email Address",
        required: true,
        type: "email",
        icon: EmailIcon,
      },
    ],
  },
  {
    section: "Online Presence",
    fields: [
      {
        name: "linkedinUrl",
        label: "LinkedIn Profile URL",
        icon: LinkedInIcon,
      },
      {
        name: "websiteUrl",
        label: "Website/Portfolio URL",
        icon: LanguageIcon,
      },
      { name: "twitterHandle", label: "X (Twitter) Handle", icon: TwitterIcon },
      { name: "githubUsername", label: "GitHub Username", icon: GitHubIcon },
      {
        name: "otherSocialProfile",
        label: "Other Social Profile",
        fullWidth: true,
      },
    ],
  },
  {
    section: "Professional Information",
    fields: [
      {
        name: "professionalTitle",
        label: "Professional Title",
        icon: WorkIcon,
        fullWidth: true,
      },
      {
        name: "summary",
        label: "Summary/Objective Statement",
        multiline: true,
        minRows: 4, // Changed from 'rows' to 'minRows'
        fullWidth: true,
        maxLength: 800,
      },
    ],
  },
];

// PersonalInformation Component
export const PersonalInformation = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [autoSaveStatus, setAutoSaveStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  // Handle field changes with validation
  const handleChange = useCallback(
    (name, value) => {
      const updatedData = {
        ...formData.personalInformation,
        [name]: value || "",
      };
      updateFormData("personalInformation", updatedData);

      validationSchema
        .validateAt(name, updatedData)
        .then(() => {
          setErrors((prev) => ({ ...prev, [name]: null }));
        })
        .catch((err) => {
          setErrors((prev) => ({ ...prev, [name]: err.message }));
        });
    },
    [formData.personalInformation, updateFormData]
  );

  // Save data to localStorage and Firestore
  const saveData = useCallback(
    async (data) => {
      try {
        localStorage.setItem("personalInformation", JSON.stringify(data));
        if (auth.user) {
          const userDocRef = doc(db, "users", auth.user.uid);
          await setDoc(
            userDocRef,
            { personalInformation: data },
            { merge: true }
          );
        }
      } catch (error) {
        console.error("Error saving data:", error);
        throw error;
      }
    },
    [auth.user]
  );

  // Debounced auto-save function
  const debouncedAutoSave = useCallback(
    debounce(async (data) => {
      try {
        setAutoSaveStatus("saving");
        await saveData(data);
        setAutoSaveStatus("success");
      } catch (err) {
        console.error("Auto-save error:", err);
        setAutoSaveStatus("error");
      }
    }, 1000),
    [saveData]
  );

  // Load data from localStorage or Firestore on mount
  useEffect(() => {
    let isMounted = true; // Flag to track if component is mounted

    const loadData = async () => {
      try {
        const localData = localStorage.getItem("personalInformation");
        if (localData) {
          const parsedData = JSON.parse(localData);
          if (isMounted) {
            updateFormData("personalInformation", parsedData);
          }
        } else if (auth.user) {
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists() && userDoc.data().personalInformation) {
            const data = userDoc.data().personalInformation;
            if (isMounted) {
              updateFormData("personalInformation", data);
              localStorage.setItem("personalInformation", JSON.stringify(data));
            }
          }
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    loadData();

    return () => {
      isMounted = false; // Cleanup flag on unmount
    };
  }, [auth.user, updateFormData]);

  // Auto-save whenever personalInformation changes
  useEffect(() => {
    if (!loading) {
      debouncedAutoSave(formData.personalInformation);
    }
  }, [formData.personalInformation, debouncedAutoSave, loading]);

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedAutoSave.cancel();
    };
  }, [debouncedAutoSave]);

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <FormSectionSubHeader title="Personal Information:" />
      <div className={classes.formContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress aria-label="Loading personal information" />
          </Grid>
        ) : (
          <form noValidate autoComplete="off">
            {formFields.map(({ section, fields }) => (
              <React.Fragment key={section}>
                <SubsectionHeader title={section} />
                <Grid container spacing={3} className={classes.formSection}>
                  {fields.map((field) => (
                    <FormField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      required={field.required}
                      type={field.type}
                      value={formData.personalInformation[field.name] || ""}
                      onChange={handleChange}
                      error={errors[field.name]}
                      tooltip={`Enter your ${field.label.toLowerCase()}`}
                      icon={field.icon ? <field.icon /> : null}
                      fullWidth={field.fullWidth}
                      multiline={field.multiline}
                      minRows={field.minRows} // Updated prop
                      // Removed 'rows' prop
                      rows={undefined} // Ensure 'rows' is not passed
                      maxLength={field.maxLength}
                      aria-label={field.label}
                    />
                  ))}
                </Grid>
              </React.Fragment>
            ))}
          </form>
        )}
      </div>
      {/* Auto-Save Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => setAutoSaveStatus(null)}
      >
        <Alert
          onClose={() => setAutoSaveStatus(null)}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert}
        >
          {autoSaveStatus === "saving"
            ? "Auto-saving..."
            : autoSaveStatus === "success"
            ? "All changes saved"
            : "Auto-save failed"}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

PersonalInformation.propTypes = {};

export default PersonalInformation;
