// src/components/GenerationsNavBar.jsx

import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  List,
  Box,
  ListItem,
  ListItemText,
  makeStyles,
  Drawer,
  IconButton,
  Divider,
  Hidden,
  TextField,
  InputAdornment,
  ListItemIcon,
  Avatar,
  useMediaQuery,
  useTheme,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Collapse,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import BusinessIcon from "@material-ui/icons/Business";
import DeleteIcon from "@material-ui/icons/Delete";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CircularProgress from "@material-ui/core/CircularProgress";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// Importing icons for all application materials
import WorkIcon from "@material-ui/icons/Work";
import DescriptionIcon from "@material-ui/icons/Description";
import AssignmentIcon from "@material-ui/icons/Assignment";
import MessageIcon from "@material-ui/icons/Message";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import SchoolIcon from "@material-ui/icons/School";
import EmailIcon from "@material-ui/icons/Email";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import PersonIcon from "@material-ui/icons/Person";
import StarIcon from "@material-ui/icons/Star";
import BuildIcon from "@material-ui/icons/Build";
import BusinessIconMaterial from "@material-ui/icons/Business";

// Import the GenerationsCalendar component
import GenerationsCalendar from "./GenerationsCalendar";

const drawerWidth = 280;
const NAVBAR_HEIGHT = 100;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    top: NAVBAR_HEIGHT,
    position: "fixed",
    height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    padding: theme.spacing(2),
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
  },
  searchField: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
    "& .MuiInputBase-root": {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
  },
  closeButton: {
    marginLeft: theme.spacing(1),
  },
  listItem: {
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& .MuiListItemIcon-root": {
        color: theme.palette.common.white,
      },
    },
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      color: theme.palette.common.white,
      "& .MuiListItemIcon-root": {
        color: theme.palette.common.white,
      },
    },
  },
  footer: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

// Define all application materials with titles and icons
const applicationMaterials = {
  resume: {
    title: "Resume",
    icon: WorkIcon,
  },
  coverLetter: {
    title: "Cover Letter",
    icon: DescriptionIcon,
  },
  references: {
    title: "References",
    icon: AssignmentIcon,
  },
  messageToRecruiter: {
    title: "Message to Recruiter",
    icon: MessageIcon,
  },
  linkedinProfileOptimization: {
    title: "LinkedIn Profile Optimization",
    icon: LinkedInIcon,
  },
  interviewPreparation: {
    title: "Interview Preparation",
    icon: SchoolIcon,
  },
  networkingEmailTemplates: {
    title: "Networking Email Templates",
    icon: EmailIcon,
  },
  careerDevelopmentPlan: {
    title: "Career Development Plan",
    icon: AssignmentIcon,
  },
  jobApplicationFollowUpEmail: {
    title: "Job Application Follow-Up Email",
    icon: EmailIcon,
  },
  salaryNegotiationScript: {
    title: "Salary Negotiation Script",
    icon: MoneyIcon,
  },
  professionalBio: {
    title: "Professional Bio",
    icon: PersonIcon,
  },
  personalBrandingTips: {
    title: "Personal Branding Tips",
    icon: StarIcon,
  },
  skillGapAnalysis: {
    title: "Skill Gap Analysis",
    icon: BuildIcon,
  },
  companyResearchReports: {
    title: "Company Research Reports",
    icon: BusinessIconMaterial,
  },
};

// Helper function to construct domain from company name
const constructDomain = (companyName) => {
  const sanitized = companyName.toLowerCase().replace(/[^a-z0-9]/g, "");
  return `${sanitized}.com`;
};

// Function to get logo URL using Clearbit Logo API
const getLogoUrl = (companyName) => {
  const domain = constructDomain(companyName);
  return `https://logo.clearbit.com/${domain}`;
};

const GenerationsNavBar = ({
  generations,
  onSelectGeneration,
  selectedGenerationId,
  drawerOpen,
  handleDrawerToggle,
  onDeleteGeneration,
  selectedApplicationMaterial,
  onSelectApplicationMaterial,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedGenerations, setExpandedGenerations] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeletionMode, setIsDeletionMode] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const filteredGenerations = useMemo(() => {
    return generations.filter(
      (generation) =>
        generation.companyName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        generation.jobTitle.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [generations, searchQuery]);

  const handleGenerationClick = (generationId) => {
    const currentIndex = expandedGenerations.indexOf(generationId);
    const newExpandedGenerations = [...expandedGenerations];

    if (currentIndex === -1) {
      newExpandedGenerations.push(generationId);
    } else {
      newExpandedGenerations.splice(currentIndex, 1);
    }

    setExpandedGenerations(newExpandedGenerations);
  };

  const handleToggleSelection = (id) => {
    const currentIndex = selectedForDeletion.indexOf(id);
    const newSelected = [...selectedForDeletion];

    if (currentIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelectedForDeletion(newSelected);
  };

  const drawerContent = (
    <>
      {/* Header with Search Field and Close Button */}
      <div className={classes.header}>
        <TextField
          className={classes.searchField}
          variant="outlined"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
            "aria-label": "Search Generations",
          }}
        />
        {isMobile && (
          <IconButton
            onClick={handleDrawerToggle}
            className={classes.closeButton}
            aria-label="Close drawer"
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <Divider />
      {/* Scrollable Content */}
      <div className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <List>
            {filteredGenerations.map((generation) => (
              <GenerationListItem
                key={generation.id}
                generation={generation}
                isExpanded={expandedGenerations.includes(generation.id)}
                isSelected={selectedGenerationId === generation.id}
                isDeletionMode={isDeletionMode}
                selectedForDeletion={selectedForDeletion}
                handleGenerationClick={handleGenerationClick}
                handleToggleSelection={handleToggleSelection}
                onSelectGeneration={onSelectGeneration}
                onSelectApplicationMaterial={onSelectApplicationMaterial}
                selectedApplicationMaterial={selectedApplicationMaterial}
                classes={classes}
                handleDrawerToggle={handleDrawerToggle}
              />
            ))}
          </List>

          {/* No Generations Found Message */}
          {filteredGenerations.length === 0 && (
            <Box p={2}>
              <Typography variant="body1" color="textSecondary">
                No job generations match your search.
              </Typography>
            </Box>
          )}
        </PerfectScrollbar>
      </div>

      {/* Footer with Calendar and Delete Button */}
      <div className={classes.footer}>
        {isDeletionMode ? (
          <>
            <Button
              onClick={() => {
                setIsDeletionMode(false);
                setSelectedForDeletion([]);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (selectedForDeletion.length > 0) {
                  setDeleteDialogOpen(true);
                }
              }}
              color="secondary"
            >
              Delete
            </Button>
          </>
        ) : (
          <>
            <IconButton
              onClick={() => setCalendarOpen(true)}
              aria-label="Open calendar"
            >
              <CalendarTodayIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setIsDeletionMode(true);
                setSelectedForDeletion([]);
              }}
              className={classes.deleteButton}
              aria-label="Delete generations"
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </div>

      {/* Delete Confirmation Dialog */}
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        generations={generations}
        selectedForDeletion={selectedForDeletion}
        onDeleteGeneration={onDeleteGeneration}
        setDeleteDialogOpen={setDeleteDialogOpen}
        setIsDeletionMode={setIsDeletionMode}
        setSelectedForDeletion={setSelectedForDeletion}
      />

      {/* Calendar Dialog */}
      <Dialog
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        fullWidth
        maxWidth="md"
        aria-labelledby="generations-calendar-dialog"
      >
        <DialogTitle id="generations-calendar-dialog">
          Application Materials Calendar
          <IconButton
            aria-label="close"
            onClick={() => setCalendarOpen(false)}
            style={{ position: "absolute", right: theme.spacing(1), top: theme.spacing(1) }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {generations.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              You have no generated content to display on the calendar.
            </Typography>
          ) : (
            <GenerationsCalendar
              generations={generations}
              onSelectGeneration={(generationId, applicationMaterialKey) => {
                // Close the dialog and select the generation and application material
                onSelectGeneration(generationId);
                if (applicationMaterialKey) {
                  onSelectApplicationMaterial(applicationMaterialKey);
                }
                setCalendarOpen(false);
                if (isMobile) {
                  handleDrawerToggle(); // Close the drawer on mobile
                }
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );

  return (
    <nav aria-label="generated applications">
      {/* Swipeable Drawer for Mobile */}
      <Hidden smUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerContent}
        </SwipeableDrawer>
      </Hidden>

      {/* Persistent Drawer for Desktop */}
      <Hidden xsDown implementation="css">
        <Drawer
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </nav>
  );
};

const GenerationListItem = ({
  generation,
  isExpanded,
  isSelected,
  isDeletionMode,
  selectedForDeletion,
  handleGenerationClick,
  handleToggleSelection,
  onSelectGeneration,
  onSelectApplicationMaterial,
  selectedApplicationMaterial,
  classes,
  handleDrawerToggle,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const materialsAvailable = useMemo(
    () =>
      Object.keys(applicationMaterials).filter(
        (key) => generation.content[key + "Json"]
      ),
    [generation.content]
  );

  const logoUrl = getLogoUrl(generation.companyName);

  return (
    <>
      <ListItem
        button
        onClick={() => {
          if (isDeletionMode) {
            handleToggleSelection(generation.id);
          } else {
            handleGenerationClick(generation.id);
          }
        }}
        className={classes.listItem}
        selected={isSelected}
      >
        {isDeletionMode && (
          <Checkbox
            edge="start"
            checked={selectedForDeletion.includes(generation.id)}
            tabIndex={-1}
            disableRipple
            onChange={() => handleToggleSelection(generation.id)}
          />
        )}
        <ListItemIcon>
          <Avatar
            src={logoUrl}
            alt={`${generation.companyName} Logo`}
            className={classes.avatar}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "";
            }}
          >
            <BusinessIcon />
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={generation.companyName}
          secondary={generation.jobTitle}
        />
        {!isDeletionMode && (isExpanded ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      <Collapse in={isExpanded && !isDeletionMode} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {materialsAvailable.map((key) => {
            const MaterialIcon = applicationMaterials[key].icon;
            return (
              <ListItem
                button
                key={key}
                className={classes.nestedListItem}
                selected={isSelected && selectedApplicationMaterial === key}
                onClick={() => {
                  onSelectGeneration(generation.id);
                  onSelectApplicationMaterial(key);
                  if (isMobile) {
                    handleDrawerToggle(); // Close the drawer on mobile
                  }
                }}
              >
                <ListItemIcon>
                  <MaterialIcon />
                </ListItemIcon>
                <ListItemText primary={applicationMaterials[key].title} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const DeleteConfirmationDialog = ({
  open,
  onClose,
  generations,
  selectedForDeletion,
  onDeleteGeneration,
  setDeleteDialogOpen,
  setIsDeletionMode,
  setSelectedForDeletion,
}) => {
  const handleDelete = () => {
    selectedForDeletion.forEach((id) => onDeleteGeneration(id));
    setDeleteDialogOpen(false);
    setIsDeletionMode(false);
    setSelectedForDeletion([]);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-dialog"
    >
      <DialogTitle id="delete-confirmation-dialog">
        Delete Job Materials
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the selected job materials?
        </Typography>
        <List>
          {selectedForDeletion.map((id) => {
            const generation = generations.find((gen) => gen.id === id);
            return (
              <ListItem key={id}>
                <ListItemText
                  primary={generation.companyName}
                  secondary={generation.jobTitle}
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GenerationsNavBar.propTypes = {
  generations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      companyName: PropTypes.string.isRequired,
      jobTitle: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired,
      jobDetails: PropTypes.object.isRequired,
    })
  ).isRequired,
  onSelectGeneration: PropTypes.func.isRequired,
  selectedGenerationId: PropTypes.string,
  drawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  onDeleteGeneration: PropTypes.func.isRequired,
  selectedApplicationMaterial: PropTypes.string,
  onSelectApplicationMaterial: PropTypes.func.isRequired,
};

GenerationListItem.propTypes = {
  generation: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDeletionMode: PropTypes.bool.isRequired,
  selectedForDeletion: PropTypes.array.isRequired,
  handleGenerationClick: PropTypes.func.isRequired,
  handleToggleSelection: PropTypes.func.isRequired,
  onSelectGeneration: PropTypes.func.isRequired,
  onSelectApplicationMaterial: PropTypes.func.isRequired,
  selectedApplicationMaterial: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  generations: PropTypes.array.isRequired,
  selectedForDeletion: PropTypes.array.isRequired,
  onDeleteGeneration: PropTypes.func.isRequired,
  setDeleteDialogOpen: PropTypes.func.isRequired,
  setIsDeletionMode: PropTypes.func.isRequired,
  setSelectedForDeletion: PropTypes.func.isRequired,
};

export default GenerationsNavBar;
