// src/components/GenerationsCalendar.jsx

import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    forwardRef,
    memo,
  } from 'react';
  import PropTypes from 'prop-types';
  import {
    Calendar,
    momentLocalizer,
  } from 'react-big-calendar';
  import moment from 'moment';
  import 'react-big-calendar/lib/css/react-big-calendar.css';
  import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
  import { withStyles, useTheme } from '@material-ui/core/styles';
  import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    useMediaQuery,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Avatar,
    IconButton,
    Collapse,
    Divider,
    CircularProgress,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Snackbar,
    Box,
    Button,
  } from '@material-ui/core';
  import CloseIcon from '@material-ui/icons/Close';
  import BusinessIcon from '@material-ui/icons/Business';
  import {
    ExpandLess,
    ExpandMore,
    ArrowBack,
    ArrowForward,
    Today as TodayIcon,
    Delete as DeleteIcon,
  } from '@material-ui/icons';
  import MuiAlert from '@material-ui/lab/Alert';
  import { applicationMaterials } from './applicationMaterials';
  import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
  
  // Initialize the localizer using moment.js
  const localizer = momentLocalizer(moment);
  
  // Drag and Drop Calendar
  const DnDCalendar = withDragAndDrop(Calendar);
  
  // Styled Components using Material-UI's withStyles
  const styles = (theme) => ({
    calendarContainer: {
      height: '100%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
    },
    eventStyled: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5, 1),
      fontSize: '0.875rem',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    dialogContentStyled: {
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
    companyLogo: {
      marginRight: theme.spacing(2),
      width: theme.spacing(5),
      height: theme.spacing(5),
      objectFit: 'contain',
    },
    listItemStyled: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    toolbarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& .rbc-toolbar-label': {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: '1rem',
      },
      '& button': {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '0.875rem',
        margin: theme.spacing(0, 0.5),
        cursor: 'pointer',
        padding: theme.spacing(0.5, 1),
        borderRadius: '4px',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      gap: theme.spacing(2),
      flexWrap: 'wrap',
    },
    formControlStyled: {
      minWidth: 120,
    },
    alertSnackbar: {
      width: '100%',
    },
  });
  
  // Helper function to construct domain from company name
  const constructDomain = (companyName) => {
    if (!companyName) return 'example.com';
    const sanitized = companyName.toLowerCase().replace(/[^a-z0-9]/g, '');
    return `${sanitized}.com`;
  };
  
  // Function to get logo URL using Clearbit Logo API with fallback
  const getLogoUrl = (companyName) => {
    const domain = constructDomain(companyName);
    return `https://logo.clearbit.com/${domain}`;
  };
  
  // Alert Component for Snackbar
  const AlertComponent = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  // Custom Toolbar Component
  const CustomToolbar = forwardRef(({ onNavigate, label }, ref) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
    const goToBack = () => {
      onNavigate('PREV');
    };
  
    const goToNext = () => {
      onNavigate('NEXT');
    };
  
    const goToToday = () => {
      onNavigate('TODAY');
    };
  
    return (
      <Box ref={ref} className="toolbarContainer">
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={goToBack}
            aria-label="Previous"
            size={isMobile ? 'small' : 'medium'}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={goToToday}
            aria-label="Today"
            size={isMobile ? 'small' : 'medium'}
          >
            <TodayIcon />
          </IconButton>
          <IconButton
            onClick={goToNext}
            aria-label="Next"
            size={isMobile ? 'small' : 'medium'}
          >
            <ArrowForward />
          </IconButton>
        </Box>
        <Typography variant="h6" aria-label="Current View">
          {label}
        </Typography>
      </Box>
    );
  });
  
  CustomToolbar.propTypes = {
    onNavigate: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  };
  
  // Memoized ListItem component for better performance
  const GenerationListItem = memo(function GenerationListItem({
    event,
    isExpanded,
    onClick,
    onDelete,
    applicationMaterials,
    onMaterialSelect,
  }) {
    return (
      <React.Fragment key={event.id}>
        <ListItem
          button
          onClick={() => onClick(event.id)}
          aria-expanded={isExpanded}
          aria-controls={`generation-details-${event.id}`}
        >
          <ListItemIcon>
            <Avatar
              src={getLogoUrl(event.generation.companyName)}
              alt={`${event.generation.companyName} Logo`}
              style={{ marginRight: 16, width: 40, height: 40 }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '';
              }}
            >
              <BusinessIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={event.generation.companyName}
            secondary={event.generation.jobTitle}
          />
          {isExpanded ? (
            <ExpandLess aria-label="Collapse details" />
          ) : (
            <ExpandMore aria-label="Expand details" />
          )}
        </ListItem>
        <Collapse
          in={isExpanded}
          timeout="auto"
          unmountOnExit
          id={`generation-details-${event.id}`}
        >
          <List component="div" disablePadding>
            {Object.keys(applicationMaterials)
              .filter((key) => event.generation.content?.[`${key}Json`])
              .map((key) => {
                const MaterialIcon = applicationMaterials[key].icon;
                return (
                  <ListItem
                    button
                    key={key}
                    onClick={() => onMaterialSelect(event, key)}
                    style={{ paddingLeft: 64 }}
                    aria-label={`Select ${applicationMaterials[key].title}`}
                  >
                    <ListItemIcon>
                      <MaterialIcon />
                    </ListItemIcon>
                    <ListItemText primary={applicationMaterials[key].title} />
                  </ListItem>
                );
              })}
          </List>
        </Collapse>
        <Divider component="li" />
        {/* Delete Button */}
        <ListItem style={{ paddingLeft: 64 }}>
          <IconButton
            aria-label="Delete generation"
            onClick={() => onDelete(event.id)}
            color="secondary"
          >
            <DeleteIcon />
          </IconButton>
        </ListItem>
      </React.Fragment>
    );
  });
  
  GenerationListItem.propTypes = {
    event: PropTypes.shape({
      id: PropTypes.string.isRequired,
      generation: PropTypes.shape({
        companyName: PropTypes.string.isRequired,
        jobTitle: PropTypes.string.isRequired,
        content: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    isExpanded: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    applicationMaterials: PropTypes.object.isRequired,
    onMaterialSelect: PropTypes.func.isRequired,
  };
  
  // Main Component
  function GenerationsCalendar({
    classes,
    generations,
    onSelectGeneration,
    onEditGeneration,
    onDeleteGeneration,
  }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
    // State management
    const [events, setEvents] = useState([]);
    const [selectedGenerations, setSelectedGenerations] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [expandedGenerations, setExpandedGenerations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('companyName');
    const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success',
    });
  
    // Memoize application materials to prevent unnecessary recalculations
    const memoizedApplicationMaterials = useMemo(
      () => applicationMaterials,
      []
    );
  
    // Effect to map generations to calendar events
    useEffect(() => {
      if (!Array.isArray(generations)) {
        console.error('Generations prop should be an array.');
        setEvents([]);
        setIsLoading(false);
        return;
      }
  
      const mappedEvents = generations.map((generation) => {
        const startDate = generation.start instanceof Date
          ? generation.start
          : generation.timestamp?.toDate
          ? generation.timestamp.toDate()
          : new Date();
        const endDate = generation.end instanceof Date
          ? generation.end
          : new Date(startDate);
        if (!(generation.end instanceof Date)) {
          endDate.setHours(startDate.getHours() + 1); // Default duration of 1 hour
        }
  
        return {
          id: generation.id,
          title: `${generation.jobTitle} at ${generation.companyName}`,
          start: startDate,
          end: endDate,
          allDay: false,
          generation,
        };
      });
      setEvents(mappedEvents);
      setIsLoading(false);
    }, [generations]);
  
    // Handler for selecting an event
    const handleSelectEvent = useCallback(
      (event) => {
        if (!event) return;
        // Select events that overlap with the selected event's time
        const selected = events.filter(
          (e) =>
            e.start <= event.end && e.end >= event.start
        );
        setSelectedGenerations(selected);
        setExpandedGenerations([]); // Reset expanded generations
        setDialogOpen(true);
      },
      [events]
    );
  
    // Custom event styling
    const eventPropGetter = useCallback(
      () => ({
        style: {
          backgroundColor: '#3f51b5',
          color: '#fff',
          borderRadius: '8px',
          padding: '4px 8px',
          fontSize: '0.875rem',
          fontWeight: 500,
        },
      }),
      []
    );
  
    // Handler for expanding/collapsing generation details
    const handleGenerationClick = useCallback(
      (generationId) => {
        setExpandedGenerations((prev) =>
          prev.includes(generationId)
            ? prev.filter((id) => id !== generationId)
            : [...prev, generationId]
        );
      },
      []
    );
  
    // Handler for selecting an application material
    const handleMaterialSelect = useCallback(
      (event, key) => {
        try {
          localStorage.setItem('selectedGenerationId', event.id);
          localStorage.setItem('selectedApplicationMaterial', key);
          onSelectGeneration(event.id, key);
          setDialogOpen(false);
          setSnackbar({
            open: true,
            message: 'Generation selected successfully!',
            severity: 'success',
          });
        } catch (error) {
          console.error('Error selecting generation:', error);
          setSnackbar({
            open: true,
            message: 'Failed to select generation.',
            severity: 'error',
          });
        }
      },
      [onSelectGeneration]
    );
  
    // Handler for search input change
    const handleSearchChange = useCallback((e) => {
      setSearchTerm(e.target.value);
    }, []);
  
    // Handler for filter type change
    const handleFilterTypeChange = useCallback((e) => {
      setFilterType(e.target.value);
    }, []);
  
    // Filtered events based on search term and filter type
    const filteredEvents = useMemo(() => {
      if (!searchTerm) return events;
      return events.filter((event) =>
        event.generation[filterType]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }, [events, searchTerm, filterType]);
  
    // Handler for closing snackbar
    const handleCloseSnackbar = useCallback((event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbar((prev) => ({ ...prev, open: false }));
    }, []);
  
    // Render loading state
    if (isLoading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress aria-label="Loading calendar" />
        </div>
      );
    }
  
    return (
      <>
        <div className={classes.calendarContainer}>
          {/* Search and Filter Controls */}
          <div className={classes.searchContainer}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              fullWidth
              inputProps={{ 'aria-label': 'Search Generations' }}
            />
            <FormControl variant="outlined" size="small" className={classes.formControlStyled}>
              <InputLabel id="filter-type-label">Filter By</InputLabel>
              <Select
                labelId="filter-type-label"
                id="filter-type"
                value={filterType}
                onChange={handleFilterTypeChange}
                label="Filter By"
                inputProps={{ 'aria-label': 'Filter Type' }}
              >
                <MenuItem value="companyName">Company Name</MenuItem>
                <MenuItem value="jobTitle">Job Title</MenuItem>
              </Select>
            </FormControl>
          </div>
  
          {/* Calendar Component */}
          <DnDCalendar
            localizer={localizer}
            events={filteredEvents}
            startAccessor="start"
            endAccessor="end"
            selectable
            onSelectEvent={handleSelectEvent}
            // Adjusted height to be responsive
            style={{ height: isMobile ? 'calc(100vh - 200px)' : 700 }}
            eventPropGetter={eventPropGetter}
            popup
            views={['month', 'week', 'day']}
            toolbar
            onEventDrop={({ event, start, end }) => {
              onEditGeneration(event.id, { start, end });
            }}
            onEventResize={({ event, start, end }) => {
              onEditGeneration(event.id, { start, end });
            }}
            resizable
            components={{
              toolbar: CustomToolbar,
              event: ({ event }) => (
                <div
                  className={classes.eventStyled}
                  role="button"
                  tabIndex={0}
                  aria-label={`Event: ${event.title}`}
                >
                  {event.title}
                </div>
              ),
            }}
            messages={{
              today: 'Today',
              previous: 'Back',
              next: 'Next',
              month: 'Month',
              week: 'Week',
              day: 'Day',
              agenda: 'Agenda',
              date: 'Date',
              time: 'Time',
              event: 'Event',
            }}
          />
        </div>
  
        {/* Generations Dialog */}
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="generations-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            disableTypography
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.background.default,
              borderBottom: '1px solid #ccc',
            }}
          >
            <Typography variant="h6" id="generations-dialog-title">
              Generated Content on{' '}
              {selectedGenerations[0]?.start
                ? moment(selectedGenerations[0].start).format('MMMM Do, YYYY')
                : ''}
            </Typography>
            <IconButton
              aria-label="Close dialog"
              onClick={() => setDialogOpen(false)}
              style={{ color: '#757575' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent className={classes.dialogContentStyled}>
            {selectedGenerations.length === 0 ? (
              <Typography>No generations found for this date.</Typography>
            ) : (
              <List>
                {selectedGenerations.map((event) => (
                  <GenerationListItem
                    key={event.id}
                    event={event}
                    isExpanded={expandedGenerations.includes(event.id)}
                    onClick={handleGenerationClick}
                    onDelete={onDeleteGeneration}
                    applicationMaterials={memoizedApplicationMaterials}
                    onMaterialSelect={handleMaterialSelect}
                  />
                ))}
              </List>
            )}
          </DialogContent>
        </Dialog>
  
        {/* Snackbar for Notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <AlertComponent
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            className={classes.alertSnackbar}
          >
            {snackbar.message}
          </AlertComponent>
        </Snackbar>
      </>
    );
  }
  
  GenerationsCalendar.propTypes = {
    classes: PropTypes.object.isRequired,
    generations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        companyName: PropTypes.string.isRequired,
        jobTitle: PropTypes.string.isRequired,
        content: PropTypes.object.isRequired,
        jobDetails: PropTypes.object.isRequired,
        timestamp: PropTypes.shape({
          toDate: PropTypes.func.isRequired,
        }).isRequired, // Firestore timestamp
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
      })
    ).isRequired,
    onSelectGeneration: PropTypes.func.isRequired,
    onEditGeneration: PropTypes.func.isRequired,
    onDeleteGeneration: PropTypes.func.isRequired,
  };
  
  export default withStyles(styles)(GenerationsCalendar);
  