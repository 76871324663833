// src/components/DashboardSection.js

import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Avatar,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  ListItemIcon,
  Collapse,
  Snackbar,
  TextField,
  InputAdornment,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link as MuiLink,
  useTheme,
  makeStyles,
  lighten,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  EmojiObjects,
  AccountCircle,
  HelpOutline,
  ExpandLess,
  ExpandMore,
  Search as SearchIcon,
  Delete as DeleteIcon,
  CalendarToday as CalendarTodayIcon,
  Business as BusinessIcon,
  Work as WorkIcon,
  Description as DescriptionIcon,
  Assignment as AssignmentIcon,
  Message as MessageIcon,
  LinkedIn as LinkedInIcon,
  School as SchoolIcon,
  Email as EmailIcon,
  AttachMoney as MoneyIcon,
  Person as PersonIcon,
  Star as StarIcon,
  Build as BuildIcon,
} from "@material-ui/icons";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { db } from "./../util/firebase";
import {
  collection,
  doc,
  getDocs,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import GenerationsCalendar from "./GenerationsCalendar";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  paper: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(3),
    gap: theme.spacing(2),
  },
  primaryButton: {
    padding: theme.spacing(1.5, 3),
    textTransform: "none",
    fontWeight: 600,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    maxWidth: 300,
    width: "100%",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.primary.dark, 0.2)
          : lighten(theme.palette.primary.main, 0.1),
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  secondaryButton: {
    padding: theme.spacing(1, 3),
    textTransform: "none",
    borderRadius: theme.spacing(1),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
    color: theme.palette.text.primary,
    maxWidth: 300,
    width: "100%",
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.grey[800], 0.1)
          : lighten(theme.palette.grey[200], 0.1),
    },
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  linkButton: {
    padding: theme.spacing(1, 3),
    textTransform: "none",
    borderRadius: theme.spacing(1),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.grey[800], 0.1)
          : lighten(theme.palette.grey[200], 0.1),
    },
  },
  quickLinksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(3),
    gap: theme.spacing(2),
  },
  welcomeMessage: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(1),
    },
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  generationList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    borderRadius: theme.spacing(1),
    "&.Mui-selected": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.primary.dark
          : theme.palette.primary.light,
      color: theme.palette.common.white,
      "& .MuiListItemIcon-root": {
        color: theme.palette.common.white,
      },
    },
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
    borderRadius: theme.spacing(1),
    "&.Mui-selected": {
      backgroundColor:
        theme.palette.type === "dark"
          ? lighten(theme.palette.primary.dark, 0.2)
          : lighten(theme.palette.primary.light, 0.5),
      color: theme.palette.common.white,
      "& .MuiListItemIcon-root": {
        color: theme.palette.common.white,
      },
    },
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  searchFieldContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    /* Reverted changes: Removed gap and flexWrap */
  },
  searchField: {
    flexGrow: 1,
    "& .MuiInputBase-root": {
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
    },
  },
  calendarIconButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: lighten(theme.palette.grey[200], 0.1),
    },
  },
  sectionHeader: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  alertBox: {
    margin: "0 auto",
    marginBottom: theme.spacing(4),
    maxWidth: 500,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
  },
  cardContent: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.spacing(2),
  },
  aboutTypography: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.6,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.95rem",
    },
  },
  dashboardEssentials: {
    marginTop: theme.spacing(4),
  },
  dialogTitle: {
    borderBottom: `1px solid ${
      theme.palette.type === "dark"
        ? lighten(theme.palette.text.primary, 0.5)
        : lighten(theme.palette.text.primary, 0.9)
    }`,
    paddingBottom: theme.spacing(2),
  },
  deleteFooter: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
}));

// Define all application materials with titles and icons
const applicationMaterials = {
  resume: {
    title: "Resume",
    icon: WorkIcon,
  },
  coverLetter: {
    title: "Cover Letter",
    icon: DescriptionIcon,
  },
  references: {
    title: "References",
    icon: AssignmentIcon,
  },
  messageToRecruiter: {
    title: "Message to Recruiter",
    icon: MessageIcon,
  },
  linkedinProfileOptimization: {
    title: "LinkedIn Profile Optimization",
    icon: LinkedInIcon,
  },
  interviewPreparation: {
    title: "Interview Preparation",
    icon: SchoolIcon,
  },
  networkingEmailTemplates: {
    title: "Networking Email Templates",
    icon: EmailIcon,
  },
  careerDevelopmentPlan: {
    title: "Career Development Plan",
    icon: AssignmentIcon,
  },
  jobApplicationFollowUpEmail: {
    title: "Job Application Follow-Up Email",
    icon: EmailIcon,
  },
  salaryNegotiationScript: {
    title: "Salary Negotiation Script",
    icon: MoneyIcon,
  },
  professionalBio: {
    title: "Professional Bio",
    icon: PersonIcon,
  },
  personalBrandingTips: {
    title: "Personal Branding Tips",
    icon: StarIcon,
  },
  skillGapAnalysis: {
    title: "Skill Gap Analysis",
    icon: BuildIcon,
  },
  companyResearchReports: {
    title: "Company Research Reports",
    icon: BusinessIcon,
  },
};

// Helper function to construct domain from company name
const constructDomain = (companyName) => {
  if (!companyName) return "";
  const sanitized = companyName.toLowerCase().replace(/[^a-z0-9]/g, "");
  return sanitized ? `${sanitized}.com` : "";
};

// Function to get logo URL using Clearbit Logo API
const getLogoUrl = (companyName) => {
  const domain = constructDomain(companyName);
  return domain ? `https://logo.clearbit.com/${domain}` : "";
};

function DashboardSection(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchQuery, setSearchQuery] = useState("");
  const [expandedGenerations, setExpandedGenerations] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeletionMode, setIsDeletionMode] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [formData, setFormData] = useState(null);
  const [formLoading, setFormLoading] = useState(true);
  const [formError, setFormError] = useState(null);

  const [generations, setGenerations] = useState([]);
  const [loadingGenerations, setLoadingGenerations] = useState(false);
  const [generationsError, setGenerationsError] = useState(null);

  // State for Calendar Dialog
  const [calendarOpen, setCalendarOpen] = useState(false);

  // Debounce search input to optimize performance
  const [debouncedSearch, setDebouncedSearch] = useState(searchQuery);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  // Load form data from localStorage
  useEffect(() => {
    try {
      const savedData = localStorage.getItem("formData");
      const parsedData = savedData ? JSON.parse(savedData) : null;
      setFormData(parsedData);
    } catch (err) {
      console.error("Error retrieving form data:", err);
      setFormError("Failed to load your saved data.");
    } finally {
      setFormLoading(false);
    }
  }, []);

  // Load generations from Firestore
  useEffect(() => {
    const loadGenerationsFromFirestore = async () => {
      if (auth.user) {
        setLoadingGenerations(true);
        const userGenerationsRef = collection(
          db,
          "users",
          auth.user.uid,
          "generations"
        );
        try {
          const querySnapshot = await getDocs(userGenerationsRef);
          const loadedGenerations = [];
          querySnapshot.forEach((docSnap) => {
            const data = docSnap.data();
            // Parse start and end into Date objects if they exist
            if (data.start) {
              data.start = data.start.toDate
                ? data.start.toDate()
                : new Date(data.start);
            }
            if (data.end) {
              data.end = data.end.toDate
                ? data.end.toDate()
                : new Date(data.end);
            }
            loadedGenerations.push({ id: docSnap.id, ...data });
          });
          setGenerations(loadedGenerations);
        } catch (error) {
          console.error("Error loading generations from Firestore:", error);
          setGenerationsError("Failed to load your generated content.");
        } finally {
          setLoadingGenerations(false);
        }
      }
    };

    loadGenerationsFromFirestore();
  }, [auth.user]);

  // Handle Continue Editing button click
  const handleContinueEditing = () => {
    router.push("/pro");
  };

  // Handle Start New Resume button click
  const handleStartNew = () => {
    try {
      localStorage.removeItem("formData");
      setFormData(null);
      router.push("/pro");
    } catch (err) {
      console.error("Error starting a new resume:", err);
      setFormError("Failed to start a new resume.");
    }
  };

  // Handle deletion of a single generation
  const handleDeleteGeneration = useCallback(
    async (generationId) => {
      // Optimistically remove from local state
      setGenerations((prevGenerations) =>
        prevGenerations.filter((gen) => gen.id !== generationId)
      );

      // Delete from Firestore
      if (auth.user) {
        const generationDocRef = doc(
          db,
          "users",
          auth.user.uid,
          "generations",
          generationId
        );
        try {
          await deleteDoc(generationDocRef);
          // console.log("Generation deleted from Firestore");
          setSnackbar({
            open: true,
            message: "Generation deleted successfully.",
            severity: "success",
          });
        } catch (error) {
          console.error("Error deleting generation from Firestore:", error);
          setSnackbar({
            open: true,
            message: "Failed to delete generation.",
            severity: "error",
          });
          // Revert local state if deletion fails
          // (Optional: Fetch generations again or handle accordingly)
        }
      }
    },
    [auth.user]
  );

  // Handle deletion of selected generations
  const handleDeleteSelectedGenerations = useCallback(() => {
    selectedForDeletion.forEach((id) => handleDeleteGeneration(id));
    setDeleteDialogOpen(false);
    setIsDeletionMode(false);
    setSelectedForDeletion([]);
  }, [selectedForDeletion, handleDeleteGeneration]);

  // Toggle expansion of a generation item
  const handleGenerationClick = (generationId) => {
    setExpandedGenerations((prevExpanded) =>
      prevExpanded.includes(generationId)
        ? prevExpanded.filter((id) => id !== generationId)
        : [...prevExpanded, generationId]
    );
  };

  // Toggle selection for deletion
  const handleToggleSelection = (id) => {
    setSelectedForDeletion((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle editing of a generation
  const handleEditGeneration = useCallback(
    async (generationId, updatedData) => {
      if (!auth.user) return;

      const generationDocRef = doc(
        db,
        "users",
        auth.user.uid,
        "generations",
        generationId
      );

      try {
        await updateDoc(generationDocRef, updatedData);
        // console.log("Generation updated in Firestore");

        // Update local state
        setGenerations((prevGenerations) =>
          prevGenerations.map((gen) =>
            gen.id === generationId ? { ...gen, ...updatedData } : gen
          )
        );

        setSnackbar({
          open: true,
          message: "Generation rescheduled successfully!",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating generation in Firestore:", error);
        setSnackbar({
          open: true,
          message: "Failed to reschedule generation.",
          severity: "error",
        });
      }
    },
    [auth.user]
  );

  // Handle selection of a generation and material
  const handleGenerationSelect = useCallback(
    (generationId, materialKey) => {
      // Store selections in localStorage
      localStorage.setItem("selectedGenerationId", generationId);
      localStorage.setItem("selectedApplicationMaterial", materialKey);
      router.push("/pro");
    },
    [router]
  );

  // Filter generations based on debounced search query
  const filteredGenerations = useMemo(() => {
    if (!debouncedSearch) return generations;
    const query = debouncedSearch.toLowerCase();
    return generations.filter(
      (generation) =>
        (generation.companyName &&
          generation.companyName.toLowerCase().includes(query)) ||
        (generation.jobTitle &&
          generation.jobTitle.toLowerCase().includes(query))
    );
  }, [generations, debouncedSearch]);

  return (
    <Section {...props}>
      <Container className={classes.container}>
        <SectionHeader
          title="Dashboard"
          subtitle=""
          size={4}
          textAlign="center"
          className={classes.sectionHeader}
        />
        {router.query.paid && auth.user.planIsActive && (
          <Box className={classes.alertBox}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
        {/* Error Alerts */}
        {formError && (
          <Box mb={3}>
            <Alert severity="error">{formError}</Alert>
          </Box>
        )}
        {generationsError && (
          <Box mb={3}>
            <Alert severity="error">{generationsError}</Alert>
          </Box>
        )}
        {/* Loading Indicators */}
        {formLoading || loadingGenerations ? (
          <Box display="flex" justifyContent="center" my={5}>
            <CircularProgress aria-label="Loading" />
          </Box>
        ) : (
          <Grid container spacing={6}>
            {/* Main Content Area */}
            <Grid item xs={12} md={7}>
              <Paper className={classes.paper} elevation={0}>
                <div className={classes.welcomeMessage}>
                  <Avatar className={classes.avatar}>
                    <AccountCircle />
                  </Avatar>
                  <Typography variant="h5">
                    Welcome back
                    {formData?.personalInformation?.firstName
                      ? `, ${formData.personalInformation.firstName}`
                      : auth.user && `, ${auth.user.email}`}
                    !
                  </Typography>
                </div>

                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.aboutTypography}
                >
                  We're excited to help you craft the perfect resume and cover
                  letter tailored to your dream job.
                </Typography>

                {formData ? (
                  <>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.aboutTypography}
                    >
                      You have an application in progress.
                    </Typography>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryButton}
                        onClick={handleContinueEditing}
                        aria-label="Continue editing your resume"
                      >
                        Pick Up Where You Left Off
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.aboutTypography}
                    >
                      You haven't started creating a resume yet.
                    </Typography>
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryButton}
                        onClick={handleStartNew}
                        aria-label="Start a new resume"
                      >
                        Start New Resume
                      </Button>
                    </div>
                  </>
                )}

                {/* Generated Content */}
                <Box mt={5}>
                  <Typography variant="h6" gutterBottom>
                    Your Generated Applications
                  </Typography>
                  {/* Search Field and Calendar Icon */}
                  <Box className={classes.searchFieldContainer}>
                    <TextField
                      className={classes.searchField}
                      variant="outlined"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      size="small"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              color="action"
                              aria-label="search icon"
                            />
                          </InputAdornment>
                        ),
                        "aria-label": "Search Generations",
                      }}
                    />
                    <IconButton
                      className={classes.calendarIconButton}
                      onClick={() => setCalendarOpen(true)}
                      aria-label="View Calendar"
                      color="primary"
                    >
                      <CalendarTodayIcon />
                    </IconButton>
                  </Box>
                  {generations.length > 0 ? (
                    <>
                      <List className={classes.generationList}>
                        {filteredGenerations.map((generation) => (
                          <React.Fragment key={generation.id}>
                            <ListItem
                              button
                              onClick={() => {
                                if (isDeletionMode) {
                                  handleToggleSelection(generation.id);
                                } else {
                                  handleGenerationClick(generation.id);
                                }
                              }}
                              className={classes.listItem}
                              aria-expanded={expandedGenerations.includes(
                                generation.id
                              )}
                              aria-label={`Generation for ${generation.companyName} as ${generation.jobTitle}`}
                            >
                              {isDeletionMode && (
                                <Checkbox
                                  edge="start"
                                  checked={selectedForDeletion.includes(
                                    generation.id
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  onChange={() =>
                                    handleToggleSelection(generation.id)
                                  }
                                  color="primary"
                                  inputProps={{
                                    "aria-label": `Select generation for ${generation.companyName}`,
                                  }}
                                />
                              )}
                              <ListItemIcon className={classes.listItemAvatar}>
                                <Avatar
                                  src={getLogoUrl(generation.companyName)}
                                  alt={`${generation.companyName} Logo`}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "";
                                  }}
                                >
                                  <BusinessIcon />
                                </Avatar>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  generation.companyName || "Unknown Company"
                                }
                                secondary={
                                  generation.jobTitle || "Unknown Title"
                                }
                              />
                              {!isDeletionMode &&
                                (expandedGenerations.includes(generation.id) ? (
                                  <ExpandLess aria-label="Collapse details" />
                                ) : (
                                  <ExpandMore aria-label="Expand details" />
                                ))}
                            </ListItem>
                            <Collapse
                              in={
                                expandedGenerations.includes(generation.id) &&
                                !isDeletionMode
                              }
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {Object.keys(applicationMaterials)
                                  .filter(
                                    (key) =>
                                      generation.content &&
                                      generation.content[`${key}Json`]
                                  )
                                  .map((key) => {
                                    const MaterialIcon =
                                      applicationMaterials[key].icon;
                                    return (
                                      <ListItem
                                        button
                                        key={key}
                                        className={classes.nestedListItem}
                                        onClick={() => {
                                          handleGenerationSelect(
                                            generation.id,
                                            key
                                          );
                                        }}
                                        aria-label={`Select ${applicationMaterials[key].title}`}
                                      >
                                        <ListItemIcon>
                                          <MaterialIcon aria-hidden="true" />
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={
                                            applicationMaterials[key].title
                                          }
                                        />
                                      </ListItem>
                                    );
                                  })}
                              </List>
                            </Collapse>
                            <Divider />
                          </React.Fragment>
                        ))}
                      </List>
                      {/* No Generations Found Message */}
                      {filteredGenerations.length === 0 && (
                        <Box p={3}>
                          <Typography variant="body1" color="textSecondary">
                            No job generations match your search.
                          </Typography>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      You haven't generated any application materials yet.
                    </Typography>
                  )}
                  {/* Footer with Delete Button */}
                  {generations.length > 0 && (
                    <Box display="flex" justifyContent="flex-end" mt={3}>
                      {isDeletionMode ? (
                        <>
                          <Button
                            onClick={() => {
                              setIsDeletionMode(false);
                              setSelectedForDeletion([]);
                            }}
                            style={{ marginRight: theme.spacing(2) }}
                            aria-label="Cancel deletion mode"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              if (selectedForDeletion.length > 0) {
                                setDeleteDialogOpen(true);
                              }
                            }}
                            color="secondary"
                            variant="contained"
                            aria-label="Delete selected generations"
                            disabled={selectedForDeletion.length === 0}
                          >
                            Delete
                          </Button>
                        </>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setIsDeletionMode(true);
                            setSelectedForDeletion([]);
                          }}
                          aria-label="Delete generations"
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  )}
                  {/* Delete Confirmation Dialog */}
                  <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    aria-labelledby="delete-confirmation-dialog"
                  >
                    <DialogTitle
                      id="delete-confirmation-dialog"
                      className={classes.dialogTitle}
                    >
                      Delete Job Materials
                    </DialogTitle>
                    <DialogContent>
                      <Typography>
                        Are you sure you want to delete the selected job
                        materials?
                      </Typography>
                      <List>
                        {selectedForDeletion.map((id) => {
                          const generation = generations.find(
                            (gen) => gen.id === id
                          );
                          return (
                            <ListItem key={id}>
                              <ListItemText
                                primary={
                                  generation?.companyName || "Unknown Company"
                                }
                                secondary={
                                  generation?.jobTitle || "Unknown Title"
                                }
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        color="primary"
                        autoFocus
                        aria-label="Cancel deletion"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleDeleteSelectedGenerations}
                        color="secondary"
                        variant="contained"
                        aria-label="Confirm deletion"
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>

                {/* Quick Links */}
                <Box mt={5}>
                  <Typography variant="h6" gutterBottom>
                    Quick Links
                  </Typography>
                  <div className={classes.quickLinksContainer}>
                    <Button
                      component={Link}
                      to="/settings/general"
                      variant="outlined"
                      className={classes.secondaryButton}
                      size="small"
                      aria-label="Go to Account Settings"
                    >
                      Account Settings
                    </Button>
                    <Button
                      component={Link}
                      to="/faq"
                      variant="outlined"
                      className={classes.secondaryButton}
                      size="small"
                      aria-label="Go to FAQ"
                    >
                      FAQ
                    </Button>
                  </div>
                </Box>
              </Paper>
            </Grid>

            {/* Sidebar Content */}
            <Grid item xs={12} md={5}>
              <Card className={classes.card} elevation={0}>
                <CardContent className={classes.cardContent}>
                  <Box className={classes.aboutSection}>
                    <Typography variant="h6" paragraph>
                      <EmojiObjects className={classes.icon} />
                      <strong>About Resume Revival</strong>
                    </Typography>
                    <Typography paragraph className={classes.aboutTypography}>
                      Resume Revival is your personal career assistant, helping
                      you create professional, ATS-optimized resumes and cover
                      letters tailored to your target jobs. Our platform
                      leverages advanced AI to highlight your strengths and
                      match your skills with job requirements.
                    </Typography>
                    <Typography paragraph className={classes.aboutTypography}>
                      Whether you're starting fresh or looking to improve your
                      existing resume, we're here to support you every step of
                      the way.
                    </Typography>
                    <Typography variant="h6" paragraph>
                      <HelpOutline className={classes.icon} />
                      <strong>Need Help?</strong>
                    </Typography>
                    <div>
                      <Button
                        component={Link}
                        to="/contact"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.linkButton}
                        aria-label="Go to Help Center"
                      >
                        Help Center
                      </Button>
                      <Button
                        component={Link}
                        to="/contact"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.linkButton}
                        aria-label="Contact Us"
                      >
                        Contact Us
                      </Button>
                    </div>

                    {/* Dashboard Essentials */}
                    <Box className={classes.dashboardEssentials}>
                      <Typography variant="h6" paragraph>
                        <strong>Dashboard Essentials</strong>
                      </Typography>
                      <Typography component="div">
                        <div>
                          You are signed in as{" "}
                          <strong>{auth.user.email}</strong>.
                        </div>

                        <div>
                          You can change your account info
                          {auth.user.stripeSubscriptionId &&
                            " and plan"} in{" "}
                          <MuiLink component={Link} to="/settings/general">
                            <strong>settings</strong>
                          </MuiLink>
                          .
                        </div>
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>

      {/* Generations Calendar Dialog */}
      <Dialog
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        fullWidth
        maxWidth="md"
        aria-labelledby="generations-calendar-dialog"
      >
        <DialogTitle
          id="generations-calendar-dialog"
          className={classes.dialogTitle}
        >
          Application Materials Calendar
        </DialogTitle>
        <DialogContent>
          {loadingGenerations ? (
            <Box display="flex" justifyContent="center" my={5}>
              <CircularProgress aria-label="Loading calendar" />
            </Box>
          ) : (
            <GenerationsCalendar
              generations={generations}
              onSelectGeneration={handleGenerationSelect}
              onEditGeneration={handleEditGeneration}
              onDeleteGeneration={handleDeleteGeneration}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() =>
          setSnackbar((prev) => ({
            ...prev,
            open: false,
          }))
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() =>
            setSnackbar((prev) => ({
              ...prev,
              open: false,
            }))
          }
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Section>
  );
}

export default DashboardSection;
