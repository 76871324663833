// SalaryNegotiationScript.js

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  boldText: { fontWeight: "bold" },
  preformattedText: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  listItem: {
    paddingLeft: 0,
  },
}));

const SalaryNegotiationScript = ({ content }) => {
  const classes = useStyles();

  if (!content || typeof content !== "object")
    return <Typography>Invalid salary negotiation script data</Typography>;

  const renderList = (items) => (
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <Typography>{item}</Typography>
        </li>
      ))}
    </ul>
  );

  return (
    <Box>
      <Typography className={classes.boldText}>Key Points:</Typography>
      {renderList(content.keyPoints)}
      <Typography className={classes.boldText}>Script:</Typography>
      <Typography className={classes.preformattedText}>
        {content.script}
      </Typography>
    </Box>
  );
};

export default SalaryNegotiationScript;
