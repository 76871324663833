// References.js

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Grid, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.text.primary,
  },
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  editableField: { marginBottom: theme.spacing(2) },
  button: { marginLeft: theme.spacing(1) },
}));

/**
 * References Component
 *
 * This component allows users to add, edit, and remove references.
 * Each reference includes fields for name, relationship, company, email, and phone.
 *
 * Props:
 * - content: An object containing the current references data.
 * - onContentChange: A function to handle updates to the references data.
 */
const References = ({ content, onContentChange }) => {
  const classes = useStyles();

  /**
   * Adds a new empty reference to the list.
   */
  const handleAddReference = () => {
    const newReference = {
      name: "",
      relationship: "",
      company: "",
      email: "",
      phone: "",
    };
    const updatedReferences = [...(content.references || []), newReference];
    onContentChange({ ...content, references: updatedReferences });
  };

  /**
   * Removes a reference at the specified index.
   *
   * @param {number} index - The index of the reference to remove.
   */
  const handleRemoveReference = (index) => {
    if (!content.references) return;
    const updatedReferences = content.references.filter((_, i) => i !== index);
    onContentChange({ ...content, references: updatedReferences });
  };

  /**
   * Updates a specific field of a reference.
   *
   * @param {number} index - The index of the reference to update.
   * @param {string} field - The field name to update.
   * @param {string} value - The new value for the field.
   */
  const handleReferenceChange = (index, field, value) => {
    if (!content.references) return;
    const updatedReferences = content.references.map((ref, i) => {
      if (i === index) {
        return { ...ref, [field]: value };
      }
      return ref;
    });
    onContentChange({ ...content, references: updatedReferences });
  };

  return (
    <Box>
      {/* Section Title */}
      <Typography variant="h6" className={classes.sectionTitle}>
        References
      </Typography>

      {/* Render existing references if any */}
      {content.references && content.references.length > 0 ? (
        content.references.map((ref, index) => (
          <Box key={index} className={classes.sectionContainer}>
            <Grid container spacing={2} alignItems="flex-end">
              {/* Reference Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  className={classes.editableField}
                  value={ref.name || ""}
                  onChange={(e) =>
                    handleReferenceChange(index, "name", e.target.value)
                  }
                  required
                />
              </Grid>

              {/* Relationship */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Relationship"
                  variant="outlined"
                  fullWidth
                  className={classes.editableField}
                  value={ref.relationship || ""}
                  onChange={(e) =>
                    handleReferenceChange(index, "relationship", e.target.value)
                  }
                  required
                />
              </Grid>

              {/* Company */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company"
                  variant="outlined"
                  fullWidth
                  className={classes.editableField}
                  value={ref.company || ""}
                  onChange={(e) =>
                    handleReferenceChange(index, "company", e.target.value)
                  }
                  required
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  className={classes.editableField}
                  type="email"
                  value={ref.email || ""}
                  onChange={(e) =>
                    handleReferenceChange(index, "email", e.target.value)
                  }
                  required
                />
              </Grid>

              {/* Phone */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  className={classes.editableField}
                  type="tel"
                  value={ref.phone || ""}
                  onChange={(e) =>
                    handleReferenceChange(index, "phone", e.target.value)
                  }
                  required
                />
              </Grid>

              {/* Remove Reference Button */}
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveReference(index)}
                    className={classes.button}
                  >
                    Remove
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))
      ) : (
        // Message when no references are added
        <Typography>No references added yet.</Typography>
      )}

      {/* Add Reference Button */}
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddReference}
        >
          Add Reference
        </Button>
      </Box>
    </Box>
  );
};

// Define PropTypes for type checking
References.propTypes = {
  /**
   * The current content/state of references.
   * Expected to have a 'references' property which is an array of reference objects.
   */
  content: PropTypes.shape({
    references: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        relationship: PropTypes.string,
        company: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
      })
    ),
  }).isRequired,

  /**
   * Function to handle updates to the references content.
   * Should accept the updated content object as its argument.
   */
  onContentChange: PropTypes.func.isRequired,
};

export default References;
