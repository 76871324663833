// src/components/FormSection2_Education.js

import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  memo,
} from "react";
import {
  Paper,
  Snackbar,
  Button,
  Typography,
  Divider,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Checkbox,
  FormControlLabel,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import {
  School as SchoolIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@material-ui/icons";
import { FormContext } from "./FormContext";
import { SubsectionHeader, FormField } from "./FormComponents";

// Firestore imports (Firebase v9 Modular)
import { db } from "../util/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Auth import
import { useAuth } from "../util/auth";

// Utility imports
import debounce from "lodash.debounce";
import isEqual from "lodash/isEqual";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  formPaper: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  formContent: {
    position: "relative",
  },
  formInnerContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  educationLevelButtons: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: "600px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  educationEntries: {
    marginTop: theme.spacing(4),
  },
  educationLevelButton: {
    flex: "1 1 auto",
    padding: theme.spacing(1, 2),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    transition: "all 0.3s",
    minWidth: "100px",
    maxWidth: "150px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.active": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      fontSize: "0.875rem",
      minWidth: "80px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "200px",
    },
  },
  educationLevelTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    fontSize: "1.3rem",
    textAlign: "center",
  },
  entryContainer: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  actionButtons: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(1),
  },
  subsection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  currentlyEnrolledCheckbox: {
    marginTop: theme.spacing(-5),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  autoSaveAlert: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  dateField: {
    "& .MuiInputLabel-root": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75) !important",
    },
  },
}));

// Initial education entry
const initialEducation = {
  educationType: "College",
  institutionName: "",
  city: "",
  stateProvince: "",
  country: "",
  degree: "",
  fieldOfStudy: "",
  minor: "",
  startDate: "",
  endDate: "",
  currentlyEnrolled: false,
  gpa: "",
  additionalInfo: "",
};

// Static data
const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "Australia",
  "Other",
];
const degrees = ["Associate", "Bachelor", "Master", "Doctorate"];

// EducationEntry Component
const EducationEntry = memo(
  ({
    index,
    education,
    handleChange,
    handleRemoveEntry,
    handleAddEntry,
    errors,
  }) => {
    const classes = useStyles();

    const isCurrentlyEnrolled = education.currentlyEnrolled;

    // Define form fields structure
    const formFields = [
      {
        section: "Institution Details",
        fields: [
          {
            name: "institutionName",
            label: "Institution Name",
            required: true,
            fullWidth: true,
            icon: SchoolIcon,
          },
          { name: "city", label: "City" },
          { name: "stateProvince", label: "State/Province" },
          {
            name: "country",
            label: "Country",
            required: true,
            select: true,
            options: countries,
          },
        ],
      },
      {
        section: "Education Dates",
        fields: [
          {
            name: "startDate",
            label: "Start Date",
            type: "date",
            required: true,
            helperText: "Enter the date you started your education",
            InputLabelProps: { shrink: true }, // Prevent label overlap
            className: classes.dateField, // Apply custom class
            placeholder: "mm/dd/yyyy",
          },
          {
            name: "endDate",
            label: "End Date",
            type: "date",
            required: !isCurrentlyEnrolled,
            hidden: isCurrentlyEnrolled,
            helperText:
              "Enter the date you completed or expect to complete your education",
            InputLabelProps: { shrink: true }, // Prevent label overlap
            className: classes.dateField, // Apply custom class
            placeholder: "mm/dd/yyyy",
          },
        ],
      },
    ];

    if (education.educationType === "College") {
      formFields.push({
        section: "Degree Information",
        fields: [
          {
            name: "degree",
            label: "Degree",
            required: true,
            select: true,
            options: degrees,
          },
          {
            name: "fieldOfStudy",
            label: "Field of Study",
            required: true,
          },
          { name: "minor", label: "Minor" },
          {
            name: "gpa",
            label: "GPA",
            type: "number",
            inputProps: { min: 0, max: 4, step: 0.01 },
          },
        ],
      });
    }

    formFields.push({
      section: "Additional Information",
      fields: [
        {
          name: "additionalInfo",
          label: "Additional Information (Honors, Awards, Activities, etc.)",
          multiline: true,
          rows: 4,
          fullWidth: true,
        },
      ],
    });

    return (
      <Box className={classes.entryContainer}>
        <SubsectionHeader
          title={`${education.educationType} Education ${index + 1}`}
        />
        {formFields.map(({ section, fields }) => (
          <React.Fragment key={section}>
            <SubsectionHeader title={section} className={classes.subsection} />
            <Grid container spacing={3} className={classes.formSection}>
              {fields.map(
                (field) =>
                  !field.hidden && (
                    <FormField
                      key={field.name}
                      name={field.name}
                      label={field.label}
                      required={field.required}
                      type={field.type}
                      value={education[field.name] || ""}
                      onChange={(name, value) =>
                        handleChange(index, name, value)
                      }
                      error={errors[field.name]}
                      tooltip={`Enter your ${field.label.toLowerCase()}`}
                      icon={field.icon ? <field.icon /> : null}
                      fullWidth={field.fullWidth}
                      multiline={field.multiline}
                      rows={field.rows}
                      select={field.select}
                      options={field.options}
                      inputProps={field.inputProps}
                      helperText={field.helperText}
                      InputLabelProps={
                        field.type === "date"
                          ? field.InputLabelProps
                          : undefined
                      }
                      className={field.className}
                      placeholder={
                        field.type === "date" ? field.placeholder : undefined
                      }
                    >
                      {field.select &&
                        field.options.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </FormField>
                  )
              )}
            </Grid>
            {section === "Education Dates" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCurrentlyEnrolled}
                    onChange={(e) =>
                      handleChange(index, "currentlyEnrolled", e.target.checked)
                    }
                    name="currentlyEnrolled"
                    color="primary"
                  />
                }
                label="I am currently enrolled"
                className={classes.currentlyEnrolledCheckbox}
              />
            )}
          </React.Fragment>
        ))}
        <Box className={classes.actionButtons}>
          <Tooltip title="Remove this education entry">
            <IconButton
              onClick={() => handleRemoveEntry(index)}
              color="secondary"
              aria-label="Remove education entry"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add another education entry">
            <IconButton
              onClick={handleAddEntry}
              color="primary"
              aria-label="Add education entry"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);

// Named Export
export const Education = () => {
  const classes = useStyles();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});
  const [autoSaveStatus, setAutoSaveStatus] = useState(null); // 'saving', 'success', 'error'
  const [loading, setLoading] = useState(false);

  const auth = useAuth(); // Get the authenticated user

  // Ref to store previous form data
  const prevFormDataRef = useRef();

  // Handle field changes with validation
  const handleChange = useCallback(
    (index, name, value) => {
      const updatedEducation = [...formData.education];
      updatedEducation[index] = {
        ...updatedEducation[index],
        [name]: value,
      };
      updateFormData("education", updatedEducation);

      // Placeholder for validation logic
      // Implement validateField as needed
      // const fieldError = validateField(name, value, formData);
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   [name]: fieldError,
      // }));
    },
    [formData.education, updateFormData]
  );

  const handleAddEntry = useCallback(() => {
    const newEntry = {
      ...initialEducation,
      educationType: formData.education[0]?.educationType || "College",
    };
    updateFormData("education", [...formData.education, newEntry]);
  }, [formData.education, updateFormData]);

  const handleRemoveEntry = useCallback(
    (index) => {
      const updatedEducation = formData.education.filter((_, i) => i !== index);
      updateFormData("education", updatedEducation);
    },
    [formData.education, updateFormData]
  );

  const handleEducationLevelChange = useCallback(
    (level) => {
      if (level === "NA") {
        updateFormData("education", [
          { ...initialEducation, educationType: "NA" },
        ]);
      } else {
        updateFormData("education", [
          { ...initialEducation, educationType: level },
        ]);
      }
    },
    [updateFormData]
  );

  const isNA = formData.education[0]?.educationType === "NA";

  // Debounced auto-save function to prevent excessive writes
  const debouncedAutoSave = useCallback(
    debounce(async (data) => {
      try {
        setAutoSaveStatus("saving");
        await saveData(data);
        setAutoSaveStatus("success");
      } catch (error) {
        console.error("Auto-save failed:", error);
        setAutoSaveStatus("error");
      }
    }, 1000),
    []
  );

  // Save data to localStorage and Firestore
  const saveData = useCallback(
    async (data) => {
      // Save to localStorage
      localStorage.setItem("education", JSON.stringify(data));

      // If user is authenticated, save to Firestore
      if (auth.user) {
        const userDocRef = doc(db, "users", auth.user.uid);
        try {
          // Use setDoc with merge: true
          await setDoc(userDocRef, { education: data }, { merge: true });
        } catch (error) {
          console.error("Error saving data:", error);
          throw error; // Re-throw to handle in debouncedAutoSave
        }
      }
    },
    [auth.user]
  );

  // Load data from localStorage or Firestore on mount
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        const localData = localStorage.getItem("education");
        if (localData) {
          const parsedData = JSON.parse(localData);
          updateFormData("education", parsedData);
        } else if (auth.user) {
          // Fetch from Firestore
          const userDocRef = doc(db, "users", auth.user.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data().education;
            if (data) {
              updateFormData("education", data);
              // Optionally, save to localStorage
              localStorage.setItem("education", JSON.stringify(data));
            }
          }
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [auth.user, updateFormData]);

  // Auto-save whenever education data changes
  useEffect(() => {
    if (!loading) {
      const currentFormData = formData.education;
      const previousFormData = prevFormDataRef.current;

      if (previousFormData && !isEqual(currentFormData, previousFormData)) {
        debouncedAutoSave(currentFormData);
      }
      prevFormDataRef.current = currentFormData;
    }
  }, [formData.education, debouncedAutoSave, loading]);

  return (
    <Paper elevation={3} className={classes.formPaper}>
      <div className={classes.formContent}>
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <div className={classes.formInnerContent}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  className={classes.educationLevelTitle}
                  align="center"
                >
                  Highest Level of Education:
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.educationLevelButtons}>
              {["College", "High School", "NA"].map((level) => (
                <Button
                  key={level}
                  className={`${classes.educationLevelButton} ${
                    formData.education[0]?.educationType === level
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleEducationLevelChange(level)}
                >
                  {level}
                </Button>
              ))}
            </div>
            <Divider />
            {!isNA && (
              <div className={classes.educationEntries}>
                {formData.education.map((education, index) => (
                  <EducationEntry
                    key={index}
                    index={index}
                    education={education}
                    handleChange={handleChange}
                    handleRemoveEntry={handleRemoveEntry}
                    handleAddEntry={handleAddEntry}
                    errors={errors[index] || {}}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {/* Auto-Save Snackbar */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(autoSaveStatus)}
        autoHideDuration={2000}
        onClose={() => setAutoSaveStatus(null)}
      >
        <Alert
          onClose={() => setAutoSaveStatus(null)}
          severity={
            autoSaveStatus === "error"
              ? "error"
              : autoSaveStatus === "success"
              ? "success"
              : "info"
          }
          variant="filled"
          className={classes.autoSaveAlert} // Apply custom styling
        >
          {autoSaveStatus === "saving"
            ? "Auto-saving..."
            : autoSaveStatus === "success"
            ? "All changes saved"
            : "Auto-save failed"}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

// Default Export
export default Education;
