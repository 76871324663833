// src/components/FormSectionHeader.js
import React from "react";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  LinearProgress,
  Button,
  IconButton,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2), // Added padding above the header
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    marginBottom: theme.spacing(1), // Reduced margin bottom to bring elements closer
  },
  buttonContainer: {
    width: 150, // Fixed width to ensure symmetry
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start", // Align buttons to the start
    [theme.breakpoints.down("sm")]: {
      width: 50, // Adjust width for mobile icon buttons
      justifyContent: "center",
    },
  },
  titleContainer: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    flexGrow: 1,
    padding: theme.spacing(0, 2), // Add horizontal padding to prevent overflow
    marginTop: 0, // Ensure no top margin
  },
  title: {
    fontWeight: 600,
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem", // Smaller font size on mobile
    },
  },
  progressBar: {
    height: 10,
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1), // Add top margin on mobile
    },
  },
  // Custom button styles to ensure consistent colors
  backButton: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
  nextButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  iconButton: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
    width: 40, // Fixed width for circular button
    height: 40, // Fixed height for circular button
    padding: 0, // Remove default padding
  },
  iconButtonNext: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[1],
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    width: 40,
    height: 40,
    padding: 0,
  },
}));

/**
 * Props:
 * - title: string - The title of the form section
 * - progress: number - Progress percentage (0-100)
 * - onBack: function - Handler for the Back button
 * - onNext: function - Handler for the Next button
 * - disableBack: boolean - Whether to disable the Back button
 * - disableNext: boolean - Whether to disable the Next button
 * - isMobile: boolean - Whether the device is mobile
 */
const FormSectionHeader = ({
  title,
  progress,
  onBack,
  onNext,
  disableBack = false,
  disableNext = false,
  isMobile = false, // Default to false
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.headerContainer}>
      <Box className={classes.headerContent}>
        {/* Back Button Container */}
        <Box className={classes.buttonContainer}>
          {isMobile ? (
            <IconButton
              onClick={onBack}
              disabled={disableBack}
              className={classes.iconButton}
              aria-label="back"
            >
              <ArrowBackIos />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              className={`${classes.backButton}`}
              onClick={onBack}
              disabled={disableBack}
              startIcon={<ArrowBackIos />}
              fullWidth
            >
              Back
            </Button>
          )}
        </Box>

        {/* Title Container */}
        <Box className={classes.titleContainer}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
        </Box>

        {/* Next Button Container */}
        <Box
          className={classes.buttonContainer}
          style={{ justifyContent: isMobile ? "center" : "flex-end" }}
        >
          {isMobile ? (
            <IconButton
              onClick={onNext}
              disabled={disableNext}
              className={classes.iconButtonNext}
              aria-label="next"
            >
              <ArrowForwardIos />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              className={`${classes.nextButton}`}
              onClick={onNext}
              disabled={disableNext}
              endIcon={<ArrowForwardIos />}
              fullWidth
            >
              Next
            </Button>
          )}
        </Box>
      </Box>

      {/* Progress Bar */}
      <Box mt={2}>
        <LinearProgress
          variant="determinate"
          value={progress}
          className={classes.progressBar}
        />
      </Box>
    </Box>
  );
};

export default React.memo(FormSectionHeader);
