// src/pages/purchase/[plan].jsx

import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Meta from "../../components/Meta";
import PageLoader from "../../components/PageLoader";
import { useAuth, requireAuth } from "../../util/auth";
import { useRouter } from "../../util/router";
import { redirectToCheckout } from "../../util/stripe";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { firebaseApp } from "../../util/firebase";

function PurchasePage(props) {
  const router = useRouter();
  const auth = useAuth();
  const [formAlert, setFormAlert] = useState();

  // Initialize Firebase Functions
  const functions = getFunctions(firebaseApp);
  // Uncomment the following line if you're using Firebase Functions emulator
  // connectFunctionsEmulator(functions, "localhost", 5001);

  useEffect(() => {
    const { session_id, plan } = router.query;
    // console.log("Router query:", router.query);

    if (auth.user && session_id) {
      // console.log("User returned from Stripe Checkout with session_id:", session_id);
      // User has returned from Stripe Checkout with a session_id
      // Fetch the Stripe session to get the plan ID
      fetch(`/api/get-stripe-session?session_id=${session_id}`)
        .then((res) => res.json())
        .then(async (session) => {
          // console.log("Stripe session data:", session);
          if (session.error) {
            throw new Error(session.error);
          }

          const stripePriceId = session.stripePriceId;

          // Call the Cloud Function to update credits
          const updateUserCredits = httpsCallable(
            functions,
            "updateUserCredits"
          );

          try {
            const result = await updateUserCredits({
              stripePriceId: stripePriceId,
              subscriptionId: session.subscriptionId,
              subscriptionStatus: session.subscriptionStatus,
            });

            // console.log("Cloud Function result:", result);

            if (result.data.status !== "success") {
              throw new Error("Failed to update user credits.");
            }

            // Redirect to dashboard or desired page
            router.push("/dashboard");
          } catch (error) {
            // console.error("Error calling Cloud Function:", error);
            setFormAlert({
              type: "error",
              message: error.message,
            });
          }
        })
        .catch((error) => {
          // console.error("Error fetching Stripe session:", error);
          setFormAlert({
            type: "error",
            message: error.message,
          });
        });
    } else if (auth.user && !session_id) {
      // console.log("User is initiating the purchase");
      // console.log("Selected plan:", plan);

      if (auth.user.planIsActive) {
        // console.log("User has an active plan, redirecting to billing settings");
        // If user already has an active plan
        // then take them to Stripe billing
        router.push("/settings/billing");
      } else {
        if (!plan) {
          setFormAlert({
            type: "error",
            message: "No plan specified. Please select a plan.",
          });
          return;
        }

        // Otherwise, redirect to checkout
        redirectToCheckout(plan)
          .then(() => {
            // console.log("Redirected to Stripe Checkout");
          })
          .catch((error) => {
            // console.error("Error redirecting to checkout:", error);
            setFormAlert({
              type: "error",
              message: error.message,
            });
          });
      }
    }
  }, [auth.user, router.query]);

  return (
    <>
      <Meta title="Purchase" />
      <PageLoader>
        {formAlert && (
          <Alert severity={formAlert.type} style={{ maxWidth: "500px" }}>
            {formAlert.message}
          </Alert>
        )}
      </PageLoader>
    </>
  );
}

export default requireAuth(PurchasePage);
