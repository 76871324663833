// NetworkingEmailTemplates.js

import React from "react";
import { Box, Typography, TextField, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  editableField: { marginBottom: theme.spacing(2) },
}));

const NetworkingEmailTemplates = ({ content, onContentChange }) => {
  const classes = useStyles();

  if (!content || typeof content !== "object")
    return <Typography>Invalid networking email templates data</Typography>;

  // Handler to update the subject of a template
  const handleSubjectChange = (index, value) => {
    const updatedTemplates = content.templates.map((template, i) =>
      i === index ? { ...template, subject: value } : template
    );
    onContentChange({ ...content, templates: updatedTemplates });
  };

  // Handler to update the body of a template
  const handleBodyChange = (index, value) => {
    const updatedTemplates = content.templates.map((template, i) =>
      i === index ? { ...template, body: value } : template
    );
    onContentChange({ ...content, templates: updatedTemplates });
  };

  return (
    <Box>
      {content.templates && content.templates.length > 0 ? (
        content.templates.map((template, index) => (
          <Box key={index} className={classes.sectionContainer}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <TextField
                  label={`Subject ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  className={classes.editableField}
                  value={template.subject}
                  onChange={(e) => handleSubjectChange(index, e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={`Body ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={4}
                  className={classes.editableField}
                  value={template.body}
                  onChange={(e) => handleBodyChange(index, e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>
        ))
      ) : (
        <Typography>No email templates available.</Typography>
      )}
    </Box>
  );
};

export default NetworkingEmailTemplates;
