// CompanyResearchReports.js

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  boldText: { fontWeight: "bold" },
  sectionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
}));

const CompanyResearchReports = ({ content }) => {
  const classes = useStyles();

  if (!content || typeof content !== "object")
    return <Typography>Invalid company research reports data</Typography>;

  return (
    <Box>

      {content.reports && content.reports.length > 0 ? (
        content.reports.map((report, index) => (
          <Box key={index} className={classes.sectionContainer}>
            <Typography className={classes.boldText}>
              {report.companyName}
            </Typography>
            <Typography>
              <span className={classes.boldText}>Industry:</span>{" "}
              {report.industry}
            </Typography>
            <Typography>
              <span className={classes.boldText}>Size:</span> {report.size}
            </Typography>
            <Typography>
              <span className={classes.boldText}>Location:</span>{" "}
              {report.location}
            </Typography>
            <Typography>
              <span className={classes.boldText}>Culture:</span>{" "}
              {report.culture}
            </Typography>
            <Typography>
              <span className={classes.boldText}>Recent News:</span>{" "}
              {report.recentNews}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography>No reports available.</Typography>
      )}
    </Box>
  );
};

export default CompanyResearchReports;
